import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { translate } from "react-i18next"
import pick from "lodash/pick"
import { push } from "connected-react-router"

import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { RESET_PASSWORD_RESET_PASSWORD } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import Text from "../components/shared/Text"
import Button from "../components/shared/Button"
import { CircleTick } from "../static/icons"

class ResetPasswordSuccess extends React.PureComponent {
  componentDidMount() {
    const { dispatch, apiRequests } = this.props
    if (apiRequests[RESET_PASSWORD_RESET_PASSWORD].successCount === 0) {
      dispatch(push("/resetPassword"))
    }
  }
  onLoginButtonClicked = () => {
    const { dispatch } = this.props
    dispatch(push("/"))
  }
  render() {
    const { t } = this.props
    return (
      <PageContainer>
        <Text variant="title" mb={2}>
          {t("reset_password_success")}
        </Text>
        <Text variant="subheading">{t("reset_password_success_desc")}</Text>
        <Text textAlign="center" pt={"64px"} pb={["140px", "104px"]}>
          <img src={CircleTick} alt="success" />
        </Text>
        <Text textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.onLoginButtonClicked}
            data-test-id={"ssoPwResetDone-relogin-button"}
            data-testid={"ssoPwResetDone-relogin-button"}
          >
            {t("retry_login")}
          </Button>
        </Text>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect((state) => ({
    apiRequests: pick(state[API_STATE_KEY], [RESET_PASSWORD_RESET_PASSWORD]),
  })),
)(ResetPasswordSuccess)
