import React, { Fragment, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { Box } from "grid-styled"
import { translate, Interpolate } from "react-i18next"
import Text from "../shared/Text"
import Button from "../shared/Button"
import { MOBILE_OTP } from "../../constants/inputs"
import TextField from "../shared/TextField"
import { GoogleReCaptchaConsumer } from "react-google-recaptcha-v3"
import { useHarmonicIntervalFn, useCounter, useToggle } from "react-use"

const useResendSMSCountDown = (initialCountDownMs, intervalMs) => {
  const [countDownMs, { dec, set }] = useCounter(
    initialCountDownMs,
    initialCountDownMs,
    0,
  )
  const [isRunning, toggleIsRunning] = useToggle(false)

  useHarmonicIntervalFn(
    () => {
      dec(intervalMs)
    },
    isRunning ? intervalMs : null,
  )

  const runCountDown = useCallback(() => {
    if (!isRunning) {
      toggleIsRunning()
      set(initialCountDownMs)
    }
  }, [isRunning, toggleIsRunning, set, initialCountDownMs])

  useEffect(() => {
    if (countDownMs === 0 && isRunning) {
      toggleIsRunning()
    }
  }, [countDownMs, toggleIsRunning, isRunning])

  return {
    isRunning,
    countDownMs,
    runCountDown,
  }
}

const VerifyOtpPage = ({
  mobilePrefix,
  mobileNumber,
  mobileOtp,
  isVerifying,
  isResending,
  hasVerified,
  onInputChanged,
  errors,
  t,
  dataTestIdPrefix,
  resendOtp,
  onSubmit,
  googleReCaptchaProps,
}) => {
  const { isRunning, countDownMs, runCountDown } = useResendSMSCountDown(
    60000,
    1000,
  )

  const onResendButtonClicked = useCallback(async () => {
    if (resendOtp) {
      resendOtp(
        googleReCaptchaProps &&
          googleReCaptchaProps.executeRecaptcha &&
          (await googleReCaptchaProps.executeRecaptcha("requestPhoneOTP")),
      )
      runCountDown()
    }
  }, [resendOtp, googleReCaptchaProps, runCountDown])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Box>
          <Text variant="title" mb={2}>
            {t("verify_mobile_otp_title")}
          </Text>
          <Text variant="subheading">{t("mobile_otp_sent_to")}</Text>
          <Text variant="body2" mb={7}>
            {`(${mobilePrefix}) ${mobileNumber}`}
          </Text>
          <TextField
            mb={2}
            name={MOBILE_OTP}
            value={mobileOtp}
            inputProps={{
              inputMode: "numeric",
              maxLength: 6,
            }}
            onChange={onInputChanged}
            placeholder={t("otp")}
            errors={errors}
            disabled={isVerifying}
            fullWidth
            withClear
            autoFocus
            data-test-id={
              dataTestIdPrefix && `${dataTestIdPrefix}-otp-inputNumber`
            }
            data-testid={
              dataTestIdPrefix && `${dataTestIdPrefix}-otp-inputNumber`
            }
          />
        </Box>
        <Box>
          <Text textAlign="center" mt={6}>
            <Button
              mb={2}
              variant="contained"
              color="primary"
              type="submit"
              isLoading={isVerifying || hasVerified}
              disabled={isEmpty(mobileOtp)}
              data-test-id={
                dataTestIdPrefix && `${dataTestIdPrefix}-login-button`
              }
              data-testid={
                dataTestIdPrefix && `${dataTestIdPrefix}-login-button`
              }
            >
              {t("login")}
            </Button>

            {isResending || !isRunning ? (
              <Button
                variant="text"
                color="primary"
                isLoading={isResending}
                onClick={onResendButtonClicked}
                data-test-id={
                  dataTestIdPrefix && `${dataTestIdPrefix}-resend-button`
                }
                data-testid={
                  dataTestIdPrefix && `${dataTestIdPrefix}-resend-button`
                }
                disabled={hasVerified}
              >
                {t("resend_otp")}
              </Button>
            ) : (
              <Text variant="subheading" textAlign="center" f={1} my={2}>
                <Interpolate
                  i18nKey={"resend_after"}
                  seconds={countDownMs / 1000}
                />
              </Text>
            )}
          </Text>
        </Box>
      </form>
    </Fragment>
  )
}

VerifyOtpPage.propTypes = {
  mobilePrefix: PropTypes.string,
  mobileNumber: PropTypes.string,
  mobileOtp: PropTypes.string,
  isVerifying: PropTypes.bool.isRequired,
  isResending: PropTypes.bool.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  errors: PropTypes.array,
  dataTestIdPrefix: PropTypes.string,
  googleReCaptchaProps: PropTypes.shape({
    executeRecaptcha: PropTypes.func,
  }),
}

const ReCaptchaContainer = (props) => {
  if (process.env.REACT_APP_RECAPTCHA_ENABLED === "true") {
    return (
      <GoogleReCaptchaConsumer>
        {(googleReCaptchaValues) => {
          return (
            <VerifyOtpPage
              googleReCaptchaProps={googleReCaptchaValues}
              {...props}
            />
          )
        }}
      </GoogleReCaptchaConsumer>
    )
  }

  return <VerifyOtpPage {...props} />
}

export default translate()(ReCaptchaContainer)
