{
  "back_to_hk01": "返回 香港01 首頁",
  "back_to_index": "返回首頁",
  "bind_phone_desc": "請輸入手機號碼，號碼將作登入及開通服務之用，請確保號碼正確。我們將發送6位數驗證碼作核實。",
  "bind_phone_title": "手機綁定",
  "check_email_reminder": "請檢查電郵，並點擊電郵內的按鈕完成登入",
  "click_link_in_email_to_reset_password": "你可以點擊電郵內的連結作密碼重設。",
  "collect_personal_data_statement": "收集個人資料聲明",
  "confirm": "確定",
  "continue": "繼續",
  "email": "電郵地址",
  "email_click_to_login": "你可以點擊電郵內的連結，一按即登入。",
  "email_login": "電郵登入",
  "email_login_desc": "我們將發送驗證電郵作登入/註冊之用，請確保所輸入的電郵地址正確。",
  "email_login_title": "輸入電郵",
  "email_otp": "使用驗證電郵登入",
  "email_otp_sent_to": "我們已傳送驗證電郵到：",
  "email_otp_verification_desc": "請點擊以下按鈕繼續。",
  "email_password": "使用密碼登入",
  "email_password_desc": "請輸入你的密碼以繼續",
  "email_resent_otp": "未能接收？請檢查垃圾郵件 或 {{resent_button}}",
  "email_resent_otp_button": "重發驗證電郵",
  "error_code": "錯誤碼",
  "error_desc": "抱歉，網頁已損壞或所輸入的網址不正確。請按下鍵返回香港01主頁。",
  "error_title": "唉！找不到你想要的網頁",
  "errors": {
    "8007": "密碼不符合格式，請輸入有效的密碼",
    "11001": "電郵地址或密碼不正確",
    "11003": "此帳戶已被禁止",
    "11004": "此帳戶已被合併，請重新登入",
    "20003": "密碼不正確",
    "30002": "驗證碼不能為空白",
    "30003": "驗證碼不正確",
    "30012": "綁定手機號碼失敗。手機號碼已被使用，或帳號已綁定其他手機號碼。",
    "30015": "你輸入的手機號碼已綁定至另一個帳戶，請輸入其他手機號碼重試。",
    "30018": "此帳戶已被禁止",
    "40004": "電郵地址不能為空白",
    "40008": "請輸入有效的電郵地址",
    "40009": "請輸入有效的手機號碼",
    "40016": "此帳戶已被禁止",
    "40017": "此帳戶已被移除",
    "40018": "此帳戶無效",
    "INVALID_APP_INFO": "URL中不包含有效的「應用程式ID」或「返回URL」。",
    "INVALID_EMAIL_ADDRESS": "請輸入有效的電郵地址",
    "INVALID_LOGIN_SESSION": "登入時段無效",
    "NEW_PASSWORD_CONFIRM_MISMATCH": "與密碼不符",
    "NEW_PASSWORD_FORMAT_INVALID": "密碼不符合格式，請輸入有效的密碼",
    "UNKNOWN_API_ERROR": "未知API錯誤",
    "USER_NOT_LOGGED_IN": "用戶未登入。",
    "SERVER_BUSY_PLEASE_RETRY": "系統繁忙中，請重新再試。",
    "INVALID_RECAPTCHA_VERIFICATION": "無法通過驗證"
  },
  "footer": "繼續即代表你已閱讀及明白有關註冊的《{{terms}}》、《{{privacy}}》及《{{collectPersonalDataStatement}}》，並同意接受其約束。",
  "forgot_password": "忘記密碼",
  "forgot_password_desc": "我們將發送一封電郵到你的信箱，點擊當中的連結以作密碼重設",
  "hide": "隱藏",
  "login": "登入",
  "login_by_phone": "手機號碼登入",
  "login_by_apple": "Apple登入",
  "login_by_google": "Google帳號登入",
  "mobile_login_desc": "手機號碼將作登入及開通服務之用，請確保號碼正確。我們將發送6位數驗證碼作核實。",
  "mobile_login_title": "輸入手機號碼",
  "mobile_otp_sent_to": "我們已傳送6位數驗證碼到：",
  "mobile_phone": "手機號碼",
  "mobile_prefix": "區號",
  "new_password": "新密碼",
  "new_password_confirm": "確認密碼",
  "or": "或",
  "otp": "驗證碼",
  "password": "密碼",
  "privacy": "私隱政策聲明",
  "register": "註冊",
  "resend_after": "{{seconds}}秒後可重新發送",
  "resend_otp": "重新發送驗證碼",
  "reset": "重設",
  "reset_password": "重設密碼",
  "reset_password_desc": "密碼長度必須至少為{{minLength}}個字元，只接受英文、數字或特別符號",
  "reset_password_email_sent": "重設電郵已發送",
  "reset_password_otp_sent_to": "重設電郵已發送：",
  "reset_password_success": "重設密碼成功",
  "reset_password_success_desc": "你的密碼已被重新設定，請點擊下面的按扭重新登入",
  "retry_login": "重新登入",
  "show": "顯示",
  "terms": "條款及細則",
  "to_connect_to": "以連結至",
  "verify_mobile_otp_title": "輸入驗證碼",
  "back": "返回",
  "retry": "重試",
  "merge_account": "合併帳號",
  "merge_account_title": "您輸入之手機號碼已挷定在另一帳戶內，建議合併帳戶以享有更多優惠。",
  "merge_account_note": "注意: 合併帳戶後，系統將合併會員資料(包括登入方式、積分、電子票及錢包設定)，而非手機號碼註冊帳戶之資料將不能復原。詳情請細閱以上條款及細則。",
  "merge_account_agreement": "本人已閱讀及同意以上有關「合併帳戶」之條款及細則",
  "merge_account_success": "恭喜你！你已成功合併帳戶。",
  "merge_account_success_redirection": "頁面將於5秒後自動跳轉；\n如沒有自動跳轉，請按「繼續」。"
}
