import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import get from "lodash/get"
import { translate } from "react-i18next"
import { Box } from "grid-styled"
import PageContainer from "../components/shared/PageContainer"
import Text from "../components/shared/Text"
import Button from "../components/shared/Button"
import { URL_TOKEN_PLACEHOLDER } from "../../../constants/placeholders"

import { STATE_KEY as APP_STATE_KEY } from "../redux/reducers/app"

const EmailVerifyOtp = ({ appInfo, location, t, match }) => {
  const token = get(match, "params.token", "")
  const verificationPageUrl = get(appInfo, "verifyPage", "")
  return (
    <PageContainer spreadContent>
      <Box>
        <Text variant="title" mb={2}>
          {t("login")}
        </Text>
        <Text variant="subheading">{t("email_otp_verification_desc")}</Text>
      </Box>
      <Text textAlign="center" mt={["208px", 0]}>
        <Button
          variant="contained"
          color="primary"
          href={verificationPageUrl.replace(URL_TOKEN_PLACEHOLDER, token)}
        >
          {t("continue")}
        </Button>
      </Text>
    </PageContainer>
  )
}

export default compose(
  translate(),
  connect(state => ({
    appInfo: get(state[APP_STATE_KEY], "appInfo"),
  })),
)(EmailVerifyOtp)
