import React, { useCallback, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { translate } from "react-i18next"
import { Link } from "react-router-dom"
import get from "lodash/get"
import pick from "lodash/pick"
import urlJoin from "proper-url-join"
import Avatar from "@material-ui/core/Avatar"
import { Flex, Box } from "grid-styled"
import qs from "qs"

import config from "../config"
import { STATE_KEY as APP_STATE_KEY } from "../redux/reducers/app"
import IconButton from "../components/shared/IconButton"
import CircleButton from "../components/shared/CircleButton"
import TextDivider from "../components/shared/TextDivider"
import PageContainer from "../components/shared/PageContainer"
import Text from "../components/shared/Text"
import { MobileSvg } from "../static/icons"
import loginMethods from "../../../constants/loginMethods"
import EmailSVG from "../static/icons/email-bubble.svg"
import GoogleSVG from "../static/icons/google.svg"
import FacebookSVG from "../static/icons/facebook-bubble.svg"
import AppleSVG from "../static/icons/apple-bubble.svg"
import HuaweiSVG from "../static/icons/huawei.svg"
import HK01SVG from "../static/hk01-logo.svg"
import tracker from "../utils/tracker"

const LogoContainer = styled(Box)`
  width: 40px;
  height: 24px;
  & > img {
    width: 100%;
  }
`
const StyledAvatar = styled(Avatar)`
  width: 16px;
  height: 16px;
`
const IconContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Home = (props) => {
  const { appInfo, t, callbackUrl, isBindPhoneRequired, location } = props

  const attr = useMemo(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    try {
      return JSON.parse(query.attr)
    } catch (__) {}
    return query
  }, [location.search])

  const getThirdPartyLink = useCallback(
    (provider) => {
      return urlJoin(config.thirdPartyLoginBaseUrl, provider, {
        query: {
          callbackUrl,
          appId: get(appInfo, "appId"),
          bindPhone: isBindPhoneRequired ? 1 : 0,
        },
      })
    },
    [appInfo, callbackUrl, isBindPhoneRequired],
  )
  const appleLink = useMemo(
    () => getThirdPartyLink("apple"),
    [getThirdPartyLink],
  )
  const facebookLink = useMemo(
    () => getThirdPartyLink("facebook"),
    [getThirdPartyLink],
  )
  const googleLink = useMemo(
    () => getThirdPartyLink("google"),
    [getThirdPartyLink],
  )
  const huaweiLink = useMemo(
    () => getThirdPartyLink("huawei"),
    [getThirdPartyLink],
  )

  const onLoginOptionsClick = useCallback(
    (action) => {
      tracker.fire({
        action,
        category: "SSOLogin",
        label: {
          appId: appInfo.appId,
          appOrigin: appInfo.homePage,
        },
      })
    },
    [appInfo],
  )

  const onThirdPartyLoginButtonClicked = useCallback(
    (action) => () => {
      onLoginOptionsClick(action)
    },
    [onLoginOptionsClick],
  )

  const onAppleClick = useCallback(
    (e) => onThirdPartyLoginButtonClicked("TapApple")(e),
    [onThirdPartyLoginButtonClicked],
  )
  const onGoogleClick = useCallback(
    (e) => onThirdPartyLoginButtonClicked("TapGoogle")(e),
    [onThirdPartyLoginButtonClicked],
  )
  const onFacebookClick = useCallback(
    (e) => onThirdPartyLoginButtonClicked("TapFacebook")(e),
    [onThirdPartyLoginButtonClicked],
  )
  const onHuaweiClick = useCallback(
    (e) => onThirdPartyLoginButtonClicked("TapHuawei")(e),
    [onThirdPartyLoginButtonClicked],
  )
  const onPhoneClick = useCallback(
    () => onLoginOptionsClick("TapMobile"),
    [onLoginOptionsClick],
  )
  const onEmailClick = useCallback(
    () => onLoginOptionsClick("TapEmail"),
    [onLoginOptionsClick],
  )

  useEffect(() => {
    tracker.fire({
      action: "land_sdk_page",
      category: "sdk_login",
      label: {
        ...attr,
        appId: appInfo.appId,
        appOrigin: appInfo.homePage,
      },
    })
  }, [attr, appInfo, appInfo.name])

  const hiddenLoginMethods = get(appInfo, "hiddenLoginMethods", [])

  const hasMobile = !hiddenLoginMethods.includes(loginMethods.MOBILE_OTP)
  const hasApple = !hiddenLoginMethods.includes(loginMethods.APPLE)
  const hasFacebook = !hiddenLoginMethods.includes(loginMethods.FACEBOOK)
  const hasAccountPassword = !(
    hiddenLoginMethods.includes(loginMethods.EMAIL_OTP) &&
    hiddenLoginMethods.includes(loginMethods.EMAIL_PASSWORD)
  )
  const hasGoogle = !hiddenLoginMethods.includes(loginMethods.GOOGLE)
  const hasHuawei = !hiddenLoginMethods.includes(loginMethods.HUAWEI)

  const isShowIcon = hasFacebook || hasAccountPassword || hasGoogle

  return (
    <PageContainer showFootnote>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Text variant="title">{t("login")}</Text>
        <LogoContainer>
          <img src={HK01SVG} alt="HK01 Logo" />
        </LogoContainer>
      </Flex>
      <Flex alignItems="center" mb={6}>
        <Text variant="subheading">{t("to_connect_to")}</Text>
        <Box ml={2} mr={1}>
          <StyledAvatar src={appInfo.iconUrl} />
        </Box>
        <Text variant="subheading">{appInfo.name}</Text>
      </Flex>
      <div
        style={{
          marginBottom: "40px",
        }}
      />

      {hasMobile && (
        <IconButton
          icon={MobileSvg}
          component={Link}
          to="/by/mobile"
          data-test-id={"ssoLogin-phoneLogin-button"}
          data-testid={"ssoLogin-phoneLogin-button"}
          onClick={onPhoneClick}
        >
          {t("login_by_phone")}
        </IconButton>
      )}

      {hasGoogle && (
        <IconButton
          icon={GoogleSVG}
          href={googleLink}
          onClick={onGoogleClick}
          data-test-id={"ssoLogin-googleLogin-button"}
          data-testid={"ssoLogin-googleLogin-button"}
        >
          {t("login_by_google")}
        </IconButton>
      )}

      {isShowIcon && (
        <TextDivider mt={4} mb={5}>
          {t("or")}
        </TextDivider>
      )}

      <IconContainer>
        {hasFacebook && (
          <CircleButton
            icon={FacebookSVG}
            href={facebookLink}
            onClick={onFacebookClick}
            data-test-id={"ssoLogin-facebookLogin-button"}
            data-testid={"ssoLogin-facebookLogin-button"}
          />
        )}

        {hasApple && (
          <CircleButton
            icon={AppleSVG}
            href={appleLink}
            onClick={onAppleClick}
            data-test-id={"ssoLogin-appleLogin-button"}
            data-testid={"ssoLogin-appleLogin-button"}
          />
        )}

        {hasHuawei && (
          <CircleButton
            icon={HuaweiSVG}
            href={huaweiLink}
            onClick={onHuaweiClick}
            mb={4}
            data-test-id={"ssoLogin-huaweiLogin-button"}
            data-testid={"ssoLogin-huaweiLogin-button"}
          />
        )}

        {hasAccountPassword && (
          <CircleButton
            icon={EmailSVG}
            to={"/by/email"}
            data-test-id={"ssoLogin-emailLogin-button"}
            data-testid={"ssoLogin-emailLogin-button"}
            onClick={onEmailClick}
          />
        )}
      </IconContainer>
    </PageContainer>
  )
}

export default connect((state) =>
  pick(state[APP_STATE_KEY], ["callbackUrl", "appInfo", "isBindPhoneRequired"]),
)(translate()(Home))
