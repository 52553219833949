import url from "url"
import get from "lodash/get"
import find from "lodash/find"
import { select, put, call, take, race } from "redux-saga/effects"
import {
  redirectToCallbackUrl,
  verifyAppInfoSuccess,
  appInitialized,
} from "../actions/controlFlow"
import {
  setCallbackUrl,
  getAppInfoRequest,
  getAppInfoSuccess,
  getAppInfoError,
  getLoginStatusSuccess,
  getLoginStatusError,
  setAppErrors,
  GET_APP_INFO_SUCCESS,
  GET_APP_INFO_ERROR,
} from "../actions/app"
import { verifyOtpRequest } from "../actions/email"
import * as api from "../../services/api"
import { STATE_KEY as APP_STATE_KEY } from "../reducers/app"
import { INVALID_APP_INFO } from "../../constants/errors"
import { URL_TOKEN_PLACEHOLDER } from "../../../../constants/placeholders"

export function* loginSuccess() {
  yield put(redirectToCallbackUrl())
}

function redirectTo(url) {
  window.location.replace(url)
}

export function* redirectBack() {
  const callbackUrl = yield select((state) =>
    get(state, [APP_STATE_KEY, "callbackUrl"]),
  )
  if (callbackUrl) {
    yield call(redirectTo, callbackUrl)
  }
}

export function* redirectToPostAccMergeCbUrl() {
  const postAccountMergeCbUrl = yield select((state) =>
    get(state, [APP_STATE_KEY, "appInfo", "postAccMergeCbUrl"]),
  )
  const callbackUrl = yield select((state) =>
    get(state, [APP_STATE_KEY, "callbackUrl"]),
  )

  if (postAccountMergeCbUrl) {
    yield call(
      redirectTo,
      postAccountMergeCbUrl.replace(URL_TOKEN_PLACEHOLDER, callbackUrl),
    )
  } else {
    yield call(redirectBack)
  }
}

export function* verifyAppInfo({ appId, callbackUrl = "" }) {
  yield put(getAppInfoRequest(appId))
  const [successAction] = yield race([
    take(GET_APP_INFO_SUCCESS),
    take(GET_APP_INFO_ERROR),
  ])

  if (successAction) {
    const { appInfo } = successAction
    const { protocol, host } = url.parse(callbackUrl)
    if (find(appInfo.origins, (origin) => origin === `${protocol}//${host}`)) {
      yield put(setCallbackUrl(callbackUrl))
      yield put(verifyAppInfoSuccess())
      return
    }
  }

  yield put(setAppErrors([INVALID_APP_INFO]))
}

export function* getAppInfo({ appId }) {
  try {
    const response = yield call(api.getAppInfo, appId)
    yield put(getAppInfoSuccess(response))
  } catch (error) {
    yield put(getAppInfoError(error.errors))
  }
}

export function* getLoginStatus(action) {
  try {
    const response = yield call(api.getLoginStatus, {
      appId: action.payload.appId,
    })
    yield put(getLoginStatusSuccess(response))
  } catch (error) {
    yield put(getLoginStatusError(error.errors))
  }
}

// get appinfo, then:
// - do nothing if `appInfo.verifyPage` is provided
// - else verify and redirect user back
export function* verifyEmailOtpFlow({ appId, token }) {
  yield put(getAppInfoRequest(appId))
  const [successAction] = yield race([
    take(GET_APP_INFO_SUCCESS),
    take(GET_APP_INFO_ERROR),
  ])

  if (successAction) {
    const { appInfo } = successAction
    if (!appInfo.verifyPage) {
      yield put(verifyOtpRequest(token))
    } else {
      yield put(appInitialized())
    }
    return
  }

  yield put(setAppErrors([INVALID_APP_INFO]))
}

export function* handleLoggedInRedirect(action) {
  const { appId, callbackUrl } = action.payload
  try {
    const response = yield call(api.syncStatus, { appId, callbackUrl })
    window.location.replace(response.redirectUrl)
  } catch (error) {
    yield put(getAppInfoError(error.errors))
  }
}
