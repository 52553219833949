import { delay } from "redux-saga"
import { put, select, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"

import get from "lodash/get"
import pick from "lodash/pick"
import intersection from "lodash/intersection"
import * as api from "../../services/api"
import { setCallbackUrl } from "../actions/app"
import {
  requestOtpSuccess,
  requestOtpError,
  verifyOtpSuccess,
  verifyOtpError,
  mergeOtpSuccess,
  mergeOtpError,
} from "../actions/bind"
import {
  displayInfomationDialog,
  displayErrorDialog,
  displaySuccessDialog,
} from "../actions/dialog"
import {
  redirectToCallbackUrl,
  redirectToPostAccMergeCbUrl,
} from "../actions/controlFlow"
import { STATE_KEY as APP_STATE_KEY } from "../reducers/app"
import { STATE_KEY as INPUT_STATE_KEY } from "../reducers/inputs"
import { MOBILE_PREFIX, MOBILE_NUMBER } from "../../constants/inputs"
import { trackEvent } from "../actions/tracking"

export function* requestOtp({ phone, reCaptchaToken }) {
  try {
    const callbackUrl = yield select((state) =>
      get(state, [APP_STATE_KEY, "callbackUrl"]),
    )
    yield call(
      api.requestOtp,
      "phone",
      phone,
      callbackUrl,
      "bind",
      {
        isCheckUsed: 1,
      },
      reCaptchaToken,
    )
    yield put(requestOtpSuccess())
    yield put(push(urlJoin("bindPhone", "verify")))
  } catch (error) {
    // Error 40013: merge account trigger
    if (error.errors.includes(40013)) {
      yield put(
        trackEvent({
          action: "trigger_account_merge",
        }),
      )
      yield put(displayInfomationDialog(true))
      yield put(requestOtpSuccess())
    } else {
      yield put(requestOtpError(error.errors))
    }
  }
}

export function* mergeRequest() {
  try {
    const callbackUrl = yield select((state) =>
      get(state, [APP_STATE_KEY, "callbackUrl"]),
    )
    const inputs = yield select((state) =>
      pick(state[INPUT_STATE_KEY], [MOBILE_PREFIX, MOBILE_NUMBER]),
    )
    yield call(
      api.requestOtp,
      "phone",
      `+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`,
      callbackUrl,
      "merge",
      { isCheckUsed: 1 },
    )
    yield put(mergeOtpSuccess())
    yield put(displayInfomationDialog(false))
    yield put(displayErrorDialog(false))
    yield put(push(urlJoin("bindPhone", "merge")))
  } catch (e) {
    yield put(mergeOtpError(e.errors))
  }
}

export function* verifyOtp({ phone, token, action }) {
  try {
    const { appInfo } = yield select((state) => state[APP_STATE_KEY])

    const { response } = yield call(api.verifyOtp, {
      method: "phone",
      token,
      authId: phone,
      action,
      appId: appInfo ? appInfo.appId : undefined,
    })
    yield put(setCallbackUrl(response.redirectUrl))

    yield put(verifyOtpSuccess())
    if (action === "merge") {
      yield put(
        trackEvent({
          action: "merge_account",
          label: {
            merge_status: "succeed",
            merge_ref: null,
          },
        }),
      )
      yield put(displaySuccessDialog(true))
      yield call(delay, 5000)
      yield put(redirectToPostAccMergeCbUrl())
    } else {
      yield put(redirectToCallbackUrl())
    }
  } catch (error) {
    if (
      action === "merge" &&
      intersection(error.errors, [30015, 30016, 30017]).length > 0
    ) {
      yield put(
        trackEvent({
          action: "merge_account",
          label: {
            merge_status: "failed",
            merge_ref: error,
          },
        }),
      )
      yield put(displayErrorDialog(true, error.errors))
      // mute the text field error and reset the loading state
      yield put(verifyOtpError([]))
    } else {
      yield put(verifyOtpError(error.errors))
    }
  }
}
