import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import get from "lodash/get"
import pick from "lodash/pick"
import { requestOtpRequest } from "../redux/actions/email"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { EMAIL } from "../constants/inputs"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as APP_STATE_KEY } from "../redux/reducers/app"
import { EMAIL_REQUEST_OTP } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import { Box } from "grid-styled"
import { translate, Interpolate } from "react-i18next"
import Text from "../components/shared/Text"
import Button from "../components/shared/Button"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"
import isEmpty from "lodash/isEmpty"
import config from "../config"
import styled from "styled-components"

const StyledResentButton = styled(Button)`
  display: inline-block;

  button {
    width: auto;
    height: auto;
    min-height: auto;
    padding: 0;
    background-color: transparent;
    line-height: 1.71;
    margin-bottom: 1px;
  }
`

class EmailOtpSent extends React.PureComponent {
  componentDidMount() {
    const { dispatch, inputs } = this.props
    if (isEmpty(inputs[EMAIL])) {
      dispatch(push(urlJoin("by", "email")))
    }
  }

  onResendButtonClicked = () => {
    const { dispatch, inputs } = this.props
    dispatch(requestOtpRequest(inputs[EMAIL]))
  }

  render() {
    const { apiRequests, inputs, appInfo, t } = this.props
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("email_login")}
          </Text>
          <Text variant="subheading">{t("email_otp_sent_to")}</Text>
          <Text variant="body2" mb={4}>
            {inputs[EMAIL]}
          </Text>
          <Text variant="subheading">{t("email_click_to_login")}</Text>
        </Box>
        <Box>
          <Text textAlign="center" mt={["208px", 0]}>
            <Button
              href={get(appInfo, "homePage", config.hk01BaseUrl)}
              variant="contained"
              color="primary"
              mb={4}
              data-test-id={"ssoEmailOtpSent-back-button"}
              data-testid={"ssoEmailOtpSent-back-button"}
            >
              {t("back_to_index")}
            </Button>
            <Text textAlign="center" variant="subheading" f={1}>
              {t("check_email_reminder")}
              <br />
              <Interpolate
                i18nKey={"email_resent_otp"}
                components={{
                  resentButton: (
                    <StyledResentButton
                      color="primary"
                      onClick={this.onResendButtonClicked}
                      isLoading={apiRequests[EMAIL_REQUEST_OTP].isFetching}
                      disableFocusRipple
                      disableRipple
                      data-test-id={"ssoEmailOtpSent-resend-button"}
                      data-testid={"ssoEmailOtpSent-resend-button"}
                    >
                      {t("email_resent_otp_button")}
                    </StyledResentButton>
                  ),
                }}
              />
            </Text>
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect((state) => ({
    appInfo: get(state[APP_STATE_KEY], "appInfo"),
    apiRequests: pick(state[API_STATE_KEY], [EMAIL_REQUEST_OTP]),
    inputs: pick(state[INPUT_STATE_KEY], [EMAIL]),
  })),
)(EmailOtpSent)
