import {
  DIALOG_INFORMATION_DISPLAY,
  DIALOG_SUCCESS_DISPLAY,
  DIALOG_ERROR_DISPLAY,
} from "../actions/dialog"

const STATE_KEY = "dialog"

const initialState = {
  information: {
    isOpened: false,
  },
  success: {
    isOpened: false,
  },
  error: {
    isOpened: false,
    errors: [],
  },
}

const dialogReducer = (state = {}, action) => {
  switch (action.type) {
    case DIALOG_INFORMATION_DISPLAY:
      return {
        ...state,
        information: {
          ...state.information,
          isOpened: action.display,
        },
      }
    case DIALOG_SUCCESS_DISPLAY:
      return {
        ...state,
        success: {
          isOpened: action.display,
        },
      }
    case DIALOG_ERROR_DISPLAY:
      return {
        ...state,
        error: {
          isOpened: action.display,
          errors: action.errors,
        },
      }
    default:
      return state
  }
}

export default dialogReducer
export { STATE_KEY, initialState }
