export const APP_INITIALIZED = "APP_INITIALIZED"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const REDIRECT_TO_CALLBACK_URL = "REDIRECT_TO_CALLBACK_URL"

export const REDIRECT_TO_POST_ACC_MERGE_CB_URL =
  "REDIRECT_TO_POST_ACC_MERGE_CB_URL"

export const VERIFY_APP_INFO_SUCCESS = "VERIFY_APP_INFO_SUCCESS"

export const RUN_VERIFY_EMAIL_OTP_FLOW = "RUN_VERIFY_EMAIL_OTP_FLOW"

export const HANDLE_LOGGED_IN_REDIRECT = "HANDLE_LOGGED_IN_REDIRECT"

export const appInitialized = () => ({
  type: APP_INITIALIZED,
})

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
})

export const redirectToPostAccMergeCbUrl = () => ({
  type: REDIRECT_TO_POST_ACC_MERGE_CB_URL,
})

export const redirectToCallbackUrl = () => ({
  type: REDIRECT_TO_CALLBACK_URL,
})

export const verifyAppInfoSuccess = () => ({
  type: VERIFY_APP_INFO_SUCCESS,
})

export const runVerifyEmailOtpFlow = (appId, token) => ({
  type: RUN_VERIFY_EMAIL_OTP_FLOW,
  appId,
  token,
})

export const handleLoggedInRedirect = ({ appId, callbackUrl }) => ({
  type: HANDLE_LOGGED_IN_REDIRECT,
  payload: { appId, callbackUrl },
})
