const DIALOG_INFOMATION = "INFORMATION"
const DIALOG_SUCESS = "SUCCESS"
const DIALOG_ERROR = "ERROR"

export const DIALOG_INFORMATION_DISPLAY = `DIALOG/${DIALOG_INFOMATION}/DISPLAY`
export const DIALOG_SUCCESS_DISPLAY = `DIALOG/${DIALOG_SUCESS}/DISPLAY`
export const DIALOG_ERROR_DISPLAY = `DIALOG/${DIALOG_ERROR}/DISPLAY`

const dialogActionCreator = dialog => display => ({
  type: `DIALOG/${dialog}/DISPLAY`,
  display,
})

export const displayInfomationDialog = dialogActionCreator(DIALOG_INFOMATION)

export const displaySuccessDialog = dialogActionCreator(DIALOG_SUCESS)

export const displayErrorDialog = (display, errors = []) => ({
  type: DIALOG_ERROR_DISPLAY,
  display,
  errors,
})
