import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { translate } from "react-i18next"
import get from "lodash/get"
import { errorDialogSelector } from "../../redux/selectors/dialog"
import Dialog, {
  Button,
  ButtonContainer,
  CoverImage,
  ContentContainer,
} from "../Dialog"
import Text from "../shared/Text"
import AccountMergeFailedImg from "../../static/account-merge-failed.png"
import { STATE_KEY as API_STATE_KEY } from "../../redux/reducers/apiRequests"
import { BIND_MERGE_OTP_REQUEST } from "../../constants/apiRequests"
import { mergeOtpRequest } from "../../redux/actions/bind"
import { trackEvent } from "../../redux/actions/tracking"

class ErrorDialog extends PureComponent {
  onBtnClicked = e => {
    const { mergeOtpRequest, trackEvent } = this.props

    trackEvent({
      action: "click_retry",
      label: {
        item_text: e.target.innerText.trim(),
      },
    })

    return mergeOtpRequest()
  }

  render() {
    const { isOpened, onClose, isLoading, errors, t } = this.props
    return (
      <Dialog small {...{ isOpened, onClose }}>
        <ContentContainer>
          <CoverImage
            src={AccountMergeFailedImg}
            width={"157px"}
            mobileWidth={"105px"}
            mb={5}
            alt={""}
          />
          <Text textAlign={"center"} variant={"dialog"} mt={[0, 5]}>
            {errors.map((error, i) => (
              <Fragment key={i}>
                {t(`errors.${error}`, {
                  defaultValue: t("errors.SERVER_BUSY_PLEASE_RETRY"),
                })}
                <br />
              </Fragment>
            ))}
          </Text>
        </ContentContainer>
        <ButtonContainer>
          <Button
            color={"primary"}
            onClick={this.onBtnClicked}
            isLoading={isLoading}
          >
            {t("retry")}
          </Button>
        </ButtonContainer>
      </Dialog>
    )
  }
}

ErrorDialog.defaultProps = {
  mergeOtpRequest: () => {},
  isLoading: false,
}

ErrorDialog.propTypes = {
  mergeOtpRequest: PropTypes.func,
  isLoading: PropTypes.bool,
}

const mapStateToProps = state => ({
  isOpened: errorDialogSelector(state).isOpened,
  errors: errorDialogSelector(state).errors,
  isLoading: get(state, [API_STATE_KEY, BIND_MERGE_OTP_REQUEST, "isFetching"]),
})

const mapDispatchToProps = {
  mergeOtpRequest,
  trackEvent,
}

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ErrorDialog)
