"use strict"

module.exports = {
  APPLE: "APPLE",
  EMAIL_PASSWORD: "EMAIL_PASSWORD",
  EMAIL_OTP: "EMAIL_OTP",
  FACEBOOK: "FACEBOOK",
  GOOGLE: "GOOGLE",
  MOBILE_OTP: "MOBILE_OTP",
  HUAWEI: "HUAWEI",
}
