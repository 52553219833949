import React from "react"
import { create } from "jss"
import Provider from "react-jss/lib/JssProvider"
import global from "jss-global"
import camelCase from "jss-camel-case"
import normalize from "normalize-jss"
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles"
import globalCss from "./globalCss"

const styleNode = document.createComment("insertion-point-jss")
document.head.insertBefore(styleNode, document.head.firstChild)

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())
jss.use(global(), camelCase())
jss.setup({
  insertionPoint: "insertion-point-jss",
})

// create and attach css
jss.createStyleSheet(normalize).attach()
jss.createStyleSheet(globalCss).attach()

// This is to control the injection order of css,
// Making the styles of `styled-components` being higher priority
const JssProvider = ({ children }) => (
  <Provider jss={jss} generateClassName={generateClassName}>
    {children}
  </Provider>
)

export default JssProvider
