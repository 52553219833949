"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/* global window */
var load = require("load-script");
var assert = require("assert");

var init = function init(_ref, sendPageView) {
  var trackingId = _ref.trackingId;

  assert(trackingId, "GA tracking id is missing");

  var config = {};

  /* https://developers.google.com/analytics/devguides/collection/gtagjs/ */
  load("https://www.googletagmanager.com/gtag/js?id=" + trackingId);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", trackingId, {
    send_page_view: sendPageView
  });

  var setCustomDimension = function setCustomDimension() {
    var customDimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    Object.entries(customDimensions).map(function (row) {
      var _row = _slicedToArray(row, 2),
          key = _row[0],
          value = _row[1];

      config["dimension" + key] = value;
    });
    gtag("config", trackingId, Object.assign({}, config, { send_page_view: false })
    // always set send_page_view to false when you call `config`, unless you want to sent page view
    );
  };

  var pageView = function pageView(location, pathname) {
    var customDimensions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var params = {};
    Object.entries(customDimensions).map(function (row) {
      var _row2 = _slicedToArray(row, 2),
          key = _row2[0],
          value = _row2[1];

      params["dimension" + key] = value;
    });

    gtag("set", { page_location: location });

    // send the page view
    gtag("config", trackingId, Object.assign({}, config, params, {
      page_title: window.document.title,
      page_path: pathname
    }));

    // call setCustomDimension() again, so that the dimensions specific to this pageview won't be persistent
    setCustomDimension();
  };

  var fire = function fire(_ref2) {
    var category = _ref2.category,
        action = _ref2.action,
        label = _ref2.label,
        value = _ref2.value,
        _ref2$nonInteraction = _ref2.nonInteraction,
        nonInteraction = _ref2$nonInteraction === undefined ? false : _ref2$nonInteraction,
        _ref2$customDimension = _ref2.customDimensions,
        customDimensions = _ref2$customDimension === undefined ? {} : _ref2$customDimension;

    var params = {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: nonInteraction
    };
    Object.entries(customDimensions).map(function (row) {
      var _row3 = _slicedToArray(row, 2),
          key = _row3[0],
          value = _row3[1];

      params["dimension" + key] = value;
    });
    gtag("event", action, params);
  };

  return {
    setCustomDimension: setCustomDimension,
    pageView: pageView,
    fire: fire,
    tracker: gtag
  };
};

module.exports = init;