const theme = {
  /* Fundamental Settings */
  space: [0, 4, 8, 12, 16, 24, 32, 56],
  breakpoints: ["37.5em", "63em", "80em"], // 600, 1008, 1280px
  rootFontSize: 16,
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 28, 32, 38, 48, 64, 72],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 600,
    bold: 700,
  },
  font:
    '"PingFang HK", PingFang-HK, PingFangHK, "微軟正黑體", "Microsoft JhengHei", -apple-system, BlinkMacSystemFont, sans-serif',
  colors: {
    black: "#000",
    white: "#fff",
    lightGrey: "#8a8f95",
    darkGrey: "#3c434e",
    grey48: "rgba(26, 30, 40, 0.48)",
    primary: "#1745ef",
    primaryLighten: "#6180f4",
    primaryDarken: "#0d32bc",
  },
  maxWidth: 1280,
  themeName: "BaseTheme",
}

theme.palette = {
  page: {
    background: "#f5f6fa",
    backgroundImage:
      "linear-gradient(to bottom, rgba(251, 252, 253, 0.98), #f5f6fa)",
  },
  common: {
    text: "#000",
    link: "#000",
  },
  login: {
    grey: {
      color: "#000000",
      backgroundColor: "#e9e9e9",
    },
    black: {
      color: "#ffffff",
      backgroundColor: "#000000",
    },
  },
}

theme.typography = {
  fontFamily: "PingFangHK-Regular",
  fontSize: "16px",
  title: `
    font-Size: 22px;
    font-family: "PingFangHK-Medium",  ${theme.font};
    line-height: 32px;
  `,
  body2: `
    font-family: "PingFangHK-Medium",  ${theme.font};
    line-height: 24px;
    font-weight: 600;
  `,
  subheading: `
    font-family: ${theme.font};
    font-weight: normal;
    color: ${theme.colors.lightGrey};
    line-height: 24px;
  `,
  display4: `
    color: ${theme.colors.lightGrey};
    line-height: 1.33;
  `,
  dialog: `
    color: ${theme.colors.darkGrey};
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  `,
  dialogTitle: `
    margin: 16px 0;
    color: ${theme.colors.darkGrey};
    font-family: "PingFangHK-Medium",  ${theme.font};
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  `,
}

export default theme
