import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import get from "lodash/get"
import pick from "lodash/pick"
import isEmpty from "lodash/isEmpty"
import concat from "lodash/concat"
import { requestOtpRequest } from "../redux/actions/email"
import { inputEmail } from "../redux/actions/inputs"
import { STATE_KEY as APP_STATE_KEY } from "../redux/reducers/app"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { EMAIL } from "../constants/inputs"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { EMAIL_REQUEST_OTP } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import { Box } from "grid-styled"
import { translate } from "react-i18next"
import Text from "../components/shared/Text"
import TextField from "../components/shared/TextField"
import Button from "../components/shared/Button"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"
import { INVALID_EMAIL_ADDRESS } from "../constants/errors"
import loginMethods from "../../../constants/loginMethods"
import tracker from "../utils/tracker"

class EmailLogin extends React.PureComponent {
  state = {
    errors: [],
  }

  isValidEmail = (email) => {
    const errors = []
    const matcher = /.+@.+\..+/ // a loose pattern to match emails
    if (!matcher.test(email)) {
      errors.push(INVALID_EMAIL_ADDRESS)
    }

    this.setState({
      errors,
    })

    return isEmpty(errors)
  }

  onInputChanged = (e) => {
    const { value } = e.target
    const { dispatch } = this.props

    dispatch(inputEmail(value))
  }

  onOtpButtonClicked = () => {
    const { dispatch, inputs, appInfo } = this.props

    tracker.fire({
      category: "SSOLogin",
      action: "TapEmailOTP",
      label: {
        appId: appInfo.appId,
        appOrigin: appInfo.homePage,
      },
    })
    if (this.isValidEmail(inputs[EMAIL])) {
      dispatch(requestOtpRequest(inputs[EMAIL]))
    }
  }

  onUsePasswordButtonClicked = () => {
    const { dispatch, inputs, appInfo } = this.props

    tracker.fire({
      category: "SSOLogin",
      action: "TapEmailLogin",
      label: {
        appId: appInfo.appId,
        appOrigin: appInfo.homePage,
      },
    })
    if (this.isValidEmail(inputs[EMAIL])) {
      dispatch(push(urlJoin("by", "email", "password")))
    }
  }

  render() {
    const { apiRequests, hiddenLoginMethods, inputs, t } = this.props
    const { errors } = this.state

    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("email_login_title")}
          </Text>
          <Text variant="subheading" mb={7}>
            {t("email_login_desc")}
          </Text>
          <TextField
            mb={3}
            type={"email"}
            name={EMAIL}
            value={inputs[EMAIL]}
            placeholder={t("email")}
            onChange={this.onInputChanged}
            errors={concat(apiRequests[EMAIL_REQUEST_OTP].errors, errors)}
            disabled={apiRequests[EMAIL_REQUEST_OTP].isFetching}
            fullWidth
            withClear
            autoFocus
            data-test-id={"ssoEmailInput-email-inputEmail"}
            data-testid={"ssoEmailInput-email-inputEmail"}
          />
        </Box>
        <Box>
          <Text textAlign="center" mt={6}>
            {!hiddenLoginMethods.includes(loginMethods.EMAIL_OTP) && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.onOtpButtonClicked}
                isLoading={apiRequests[EMAIL_REQUEST_OTP].isFetching}
                mb={2}
                disabled={isEmpty(inputs[EMAIL])}
                data-test-id={"ssoEmailInput-otpLogin-button"}
                data-testid={"ssoEmailInput-otpLogin-button"}
              >
                {t("email_otp")}
              </Button>
            )}
            {!hiddenLoginMethods.includes(loginMethods.EMAIL_PASSWORD) && (
              <Button
                variant="text"
                color="primary"
                onClick={this.onUsePasswordButtonClicked}
                disabled={isEmpty(inputs[EMAIL])}
                data-test-id={"ssoEmailInput-passwordLogin-button"}
                data-testid={"ssoEmailInput-passwordLogin-button"}
              >
                {t("email_password")}
              </Button>
            )}
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect((state) => ({
    appInfo: state.app.appInfo,
    hiddenLoginMethods: get(
      state[APP_STATE_KEY],
      ["appInfo", "hiddenLoginMethods"],
      [],
    ),
    apiRequests: pick(state[API_STATE_KEY], [EMAIL_REQUEST_OTP]),
    inputs: pick(state[INPUT_STATE_KEY], [EMAIL]),
  })),
)(EmailLogin)
