import { put, select, call } from "redux-saga/effects"
import { replace } from "connected-react-router"
import urlJoin from "proper-url-join"

import get from "lodash/get"
import { setCallbackUrl } from "../actions/app"
import * as api from "../../services/api"
import {
  requestOtpSuccess,
  requestOtpError,
  verifyOtpSuccess,
  verifyOtpError,
} from "../actions/mobile"
import { loginSuccess } from "../actions/controlFlow"
import { STATE_KEY as APP_STATE_KEY } from "../reducers/app"

export function* requestOtp({ phone, reCaptchaToken }) {
  try {
    const callbackUrl = yield select((state) =>
      get(state, [APP_STATE_KEY, "callbackUrl"]),
    )
    yield call(
      api.requestOtp,
      "phone",
      phone,
      callbackUrl,
      "authenticate",
      undefined,
      reCaptchaToken,
    )
    yield put(requestOtpSuccess())
    yield put(replace(urlJoin("by", "mobile", "verify")))
  } catch (error) {
    yield put(requestOtpError(error.errors))
  }
}

export function* verifyOtp({ phone, token }) {
  try {
    const { appInfo, isBindPhoneRequired } = yield select(
      (state) => state[APP_STATE_KEY],
    )

    const { response } = yield call(api.verifyOtp, {
      method: "phone",
      token,
      authId: phone,
      action: "authenticate",
      appId: appInfo ? appInfo.appId : undefined,
      bindPhone: isBindPhoneRequired ? 1 : 0,
    })
    yield put(setCallbackUrl(response.redirectUrl))

    yield put(verifyOtpSuccess())
    yield put(loginSuccess())
  } catch (error) {
    yield put(verifyOtpError(error.errors))
  }
}
