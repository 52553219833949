import axios from "axios"
import get from "lodash/get"
import config from "../../config"
import { UNKNOWN_API_ERROR } from "../../constants/errors"

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
})

apiClient.interceptors.response.use(
  response => response.data,
  e => {
    const error = new Error()
    error.errors = get(e.response, "data.errors", [UNKNOWN_API_ERROR])
    throw error
  },
)

export default apiClient
