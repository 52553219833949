import React from "react"
import { connect } from "react-redux"
import pick from "lodash/pick"
import { requestOtpRequest } from "../redux/actions/mobile"
import { inputMobilePrefix, inputMobileNumber } from "../redux/actions/inputs"
import PageContainer from "../components/shared/PageContainer"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { MOBILE_REQUEST_OTP } from "../constants/apiRequests"
import { MOBILE_PREFIX, MOBILE_NUMBER } from "../constants/inputs"
import RequestOtpPage from "../components/Mobile/RequestOtpPage"
import tracker from "../utils/tracker"
import GoogleReCaptchaProvider from "../components/GoogleReCaptchaProvider"

class MobileLogin extends React.PureComponent {
  onInputChanged = (e) => {
    const { name, value } = e.target
    const { dispatch } = this.props

    if (name === MOBILE_PREFIX) {
      dispatch(inputMobilePrefix(value))
    } else {
      dispatch(inputMobileNumber(value))
    }
  }
  dispatchRequestOtp = (reCaptchaToken) => {
    const { inputs, appInfo } = this.props
    const { dispatch } = this.props
    tracker.fire({
      category: "SSOLogin",
      action: "TapMobileContinue",
      label: {
        appId: appInfo.appId,
        appOrigin: appInfo.homePage,
      },
    })
    dispatch(
      requestOtpRequest(
        `+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`,
        reCaptchaToken,
      ),
    )
  }
  render() {
    const { apiRequests, inputs } = this.props
    return (
      <GoogleReCaptchaProvider>
        <PageContainer>
          <RequestOtpPage
            mobilePrefix={inputs[MOBILE_PREFIX]}
            mobileNumber={inputs[MOBILE_NUMBER]}
            isFetching={apiRequests[MOBILE_REQUEST_OTP].isFetching}
            onInputChanged={this.onInputChanged}
            onSubmit={this.dispatchRequestOtp}
            errors={apiRequests[MOBILE_REQUEST_OTP].errors}
            dataTestIdPrefix={"ssoPhoneInput"}
          />
        </PageContainer>
      </GoogleReCaptchaProvider>
    )
  }
}

export default connect((state) => ({
  appInfo: state.app.appInfo,
  apiRequests: pick(state[API_STATE_KEY], [MOBILE_REQUEST_OTP]),
  inputs: pick(state[INPUT_STATE_KEY], [MOBILE_PREFIX, MOBILE_NUMBER]),
}))(MobileLogin)
