import trackerClient from "@hk01-digital/data-tracker-client"
import Cookies from "js-cookie"

const getUserId = () => Cookies.get("hk01_annonymous_id") || "dummy-id"

const tracker = trackerClient(
  {
    GA: {
      trackingId: "dummy-id",
    },
    Piwik: {
      trackingUrl: process.env.PIWIK_TRACKING_URL,
      siteId: process.env.PIWIK_SITE_ID,
      userId: getUserId(),
      isSPA: true,
    },
  },
  false,
)

const enchanceTracker = tracker => {
  tracker.origFire = tracker.fire

  tracker.fire = payload =>
    tracker.origFire(
      { Piwik: true },
      {
        ...payload,
        label: JSON.stringify({
          ...(payload.label || {}),
        }),
      },
    )

  return tracker
}

/** @see {https://docs.google.com/spreadsheets/d/16_M4nUYIZCfyxn0BemE8PeAdOTtu5D5pncmBYOFRAkc/edit#gid=61538791} */
const createEvent = ({ action, label = {} }) => ({
  category: "account_merge",
  action,
  label: {
    uid: tracker.Piwik.getUserId(),
    bucket_id: "0000",
    ts: Date.now(),
    ...label,
  },
})

export default enchanceTracker(tracker)
export { createEvent }
