import {
  EMAIL_REQUEST_OTP,
  EMAIL_VERIFY_OTP,
  EMAIL_AUTHENTICATE,
} from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const REQUEST_OTP_REQUEST = `${EMAIL_REQUEST_OTP}/${REQUEST}`
export const REQUEST_OTP_SUCCESS = `${EMAIL_REQUEST_OTP}/${SUCCESS}`
export const REQUEST_OTP_ERROR = `${EMAIL_REQUEST_OTP}/${ERROR}`

export const VERIFY_OTP_REQUEST = `${EMAIL_VERIFY_OTP}/${REQUEST}`
export const VERIFY_OTP_SUCCESS = `${EMAIL_VERIFY_OTP}/${SUCCESS}`
export const VERIFY_OTP_ERROR = `${EMAIL_VERIFY_OTP}/${ERROR}`

export const AUTHENTICATE_REQUEST = `${EMAIL_AUTHENTICATE}/${REQUEST}`
export const AUTHENTICATE_SUCCESS = `${EMAIL_AUTHENTICATE}/${SUCCESS}`
export const AUTHENTICATE_ERROR = `${EMAIL_AUTHENTICATE}/${ERROR}`

export const requestOtpRequest = email => ({
  type: REQUEST_OTP_REQUEST,
  email,
})

export const requestOtpSuccess = () => ({
  type: REQUEST_OTP_SUCCESS,
})

export const requestOtpError = errors => ({
  type: REQUEST_OTP_ERROR,
  errors,
})

export const verifyOtpRequest = token => ({
  type: VERIFY_OTP_REQUEST,
  token,
})

export const verifyOtpSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
})

export const verifyOtpError = errors => ({
  type: VERIFY_OTP_ERROR,
  errors,
})

export const authenticateRequest = (email, password) => ({
  type: AUTHENTICATE_REQUEST,
  email,
  password,
})

export const authenticateSuccess = () => ({
  type: AUTHENTICATE_SUCCESS,
})

export const authenticateError = errors => ({
  type: AUTHENTICATE_ERROR,
  errors,
})
