import request from "./request"
import * as paths from "./paths"

export const authenticate = ({
  method,
  authId,
  authSecret,
  appId,
  redirectUrl,
  bindPhone,
}) =>
  request.post(paths.authenticate, {
    method,
    authId,
    authSecret,
    appId,
    redirectUrl,
    bindPhone,
  })

export const requestOtp = (
  method,
  authId,
  redirectUrl,
  action,
  { bindPhone, appId, isCheckUsed = 0 } = {},
  reCaptchaToken,
) =>
  request.post(paths.requestOtp, {
    method,
    authId,
    redirectUrl,
    action,
    isCheckUsed,
    state: {
      bindPhone,
      appId,
    },
    reCaptchaToken,
  })

export const verifyOtp = ({
  method,
  token,
  authId,
  action,
  appId,
  bindPhone,
}) =>
  // the `action` value is for tracking only
  request.post(paths.verifyOtp, {
    method,
    token,
    authId,
    action,
    appId,
    bindPhone,
  })

export const getAppInfo = (appId) =>
  request.get(paths.getAppInfo, {
    params: {
      appId,
    },
  })

export const getLoginStatus = ({ appId }) =>
  request.get(paths.getLoginStatus, {
    params: {
      appId,
    },
  })

export const resetPassword = (token, newPassword) =>
  request.post(paths.resetPassword, {
    token,
    newPassword,
  })

export const sendAttribution = (attr) =>
  request.post(paths.sendAttribution, {
    attr,
  })

export const syncStatus = ({ appId, callbackUrl }) =>
  request.get(paths.syncStatus, { params: { appId, callbackUrl } })
