import React from "react"
import { Route, Switch } from "react-router"
import urlJoin from "proper-url-join"

import LoginRoutes from "./LoginRoutes"
import Home from "../pages/Home"
import EmailVerifyOtp from "../pages/EmailVerifyOtp"
import BindPhone from "../pages/BindPhone"
import BindPhoneVerifyOtp from "../pages/BindPhoneVerifyOtp"
import ForgotPassword from "../pages/ForgotPassword"
import ForgotPasswordOtpSent from "../pages/ForgotPasswordOtpSent"
import ResetPassword from "../pages/ResetPassword"
import ResetPasswordSuccess from "../pages/ResetPasswordSuccess"
import NotFound from "../pages/NotFound"

const routes = (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/by" component={LoginRoutes} />

    <Route path="/bindPhone" component={BindPhone} exact />
    <Route
      path={urlJoin("bindPhone", ":type")}
      component={BindPhoneVerifyOtp}
    />
    <Route path="/forgotPassword" component={ForgotPassword} exact />
    <Route
      path={urlJoin("forgotPassword", "otpSent")}
      component={ForgotPasswordOtpSent}
    />

    <Route
      path={urlJoin("member", "verify", ":token")}
      component={EmailVerifyOtp}
    />

    <Route
      path={urlJoin("member", "resetPassword", "success")}
      component={ResetPasswordSuccess}
    />
    <Route
      path={urlJoin("member", "resetPassword", ":token")}
      component={ResetPassword}
    />

    <Route component={NotFound} />
  </Switch>
)

export default routes
