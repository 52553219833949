import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import pick from "lodash/pick"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"

import tracker from "../utils/tracker"
import { requestOtpRequest, verifyOtpRequest } from "../redux/actions/mobile"
import { inputMobileOtp } from "../redux/actions/inputs"
import PageContainer from "../components/shared/PageContainer"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { MOBILE_REQUEST_OTP, MOBILE_VERIFY_OTP } from "../constants/apiRequests"
import { MOBILE_PREFIX, MOBILE_NUMBER, MOBILE_OTP } from "../constants/inputs"
import VerifyOtpPage from "../components/Mobile/VerifyOtpPage"
import GoogleReCaptchaProvider from "../components/GoogleReCaptchaProvider"

const MobileVerifyOtp = ({ apiRequests, inputs, dispatch, appInfo }) => {
  useEffect(() => {
    if (apiRequests[MOBILE_REQUEST_OTP].successCount === 0) {
      dispatch(push(urlJoin("by", "mobile")))
    }
  }, [apiRequests, dispatch])

  const onInputChanged = useCallback(
    (e) => {
      dispatch(inputMobileOtp(e.target.value))
    },
    [dispatch],
  )

  const onRequestOTP = useCallback(
    (reCaptchaToken) => {
      tracker.fire({
        category: "SSOLogin",
        action: "ClickRetrySMS",
        label: {
          appId: appInfo.appId,
          appOrigin: appInfo.homePage,
        },
      })
      dispatch(
        requestOtpRequest(
          `+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`,
          reCaptchaToken,
        ),
      )
    },
    [inputs, dispatch, appInfo.appId, appInfo.homePage],
  )

  const onVerifyOTP = useCallback(() => {
    tracker.fire({
      category: "SSOLogin",
      action: "TapMobileLogin",
      label: {
        appId: appInfo.appId,
        appOrigin: appInfo.homePage,
      },
    })
    dispatch(
      verifyOtpRequest(
        `+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`,
        inputs[MOBILE_OTP],
      ),
    )
  }, [inputs, dispatch, appInfo.appId, appInfo.homePage])

  return (
    <GoogleReCaptchaProvider>
      <PageContainer spreadContent>
        <VerifyOtpPage
          mobilePrefix={inputs[MOBILE_PREFIX]}
          mobileNumber={inputs[MOBILE_NUMBER]}
          mobileOtp={inputs[MOBILE_OTP]}
          isVerifying={apiRequests[MOBILE_VERIFY_OTP].isFetching}
          isResending={apiRequests[MOBILE_REQUEST_OTP].isFetching}
          hasVerified={apiRequests[MOBILE_VERIFY_OTP].successCount > 0}
          onInputChanged={onInputChanged}
          onSubmit={onVerifyOTP}
          resendOtp={onRequestOTP}
          errors={apiRequests[MOBILE_VERIFY_OTP].errors}
          dataTestIdPrefix={"ssoPhoneOtp"}
        />
      </PageContainer>
    </GoogleReCaptchaProvider>
  )
}

export default connect((state) => ({
  appInfo: state.app.appInfo,
  apiRequests: pick(state[API_STATE_KEY], [
    MOBILE_REQUEST_OTP,
    MOBILE_VERIFY_OTP,
  ]),
  inputs: pick(state[INPUT_STATE_KEY], [
    MOBILE_PREFIX,
    MOBILE_NUMBER,
    MOBILE_OTP,
  ]),
}))(MobileVerifyOtp)
