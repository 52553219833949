import React, { useCallback } from "react"
import { GoogleReCaptchaConsumer } from "react-google-recaptcha-v3"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { Box } from "grid-styled"
import { translate } from "react-i18next"
import Text from "../shared/Text"
import Button from "../shared/Button"
import Icon from "../shared/Icon"
import { NextSvg } from "../../static/icons"
import PhoneField from "../shared/PhoneField"
import Spinner from "../shared/Spinner"
import { Flex } from "grid-styled"
import styled from "styled-components"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"

const SpinnerContainer = styled(Flex)`
  ${MediaQuery.lessThan(TABLET)`
    min-height: 100vh;
  `};
`

const RequestOtpPage = ({
  variant,
  mobilePrefix,
  mobileNumber,
  isFetching,
  onInputChanged,
  onSubmit,
  errors,
  t,
  dataTestIdPrefix,
  googleReCaptchaProps,
}) => {
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      onSubmit(
        googleReCaptchaProps &&
          googleReCaptchaProps.executeRecaptcha &&
          (await googleReCaptchaProps.executeRecaptcha("requestPhoneOTP")),
      )
    },
    [googleReCaptchaProps, onSubmit],
  )

  if (googleReCaptchaProps && !googleReCaptchaProps.executeRecaptcha) {
    return (
      <SpinnerContainer justifyContent="center">
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Text variant="title" mb={2}>
          {variant === "mobile_login"
            ? t("mobile_login_title")
            : t("bind_phone_title")}
        </Text>
        <Text variant="subheading" mb={7}>
          {variant === "bind_phone"
            ? t("mobile_login_desc")
            : t("bind_phone_desc")}
        </Text>
        <PhoneField
          onChange={onInputChanged}
          mobilePrefixValue={mobilePrefix}
          mobileNumberValue={mobileNumber}
          errors={errors}
          disabled={isFetching}
          withClear
          fullWidth
          autoFocus
          dataTestIdPrefix={dataTestIdPrefix}
        />
        <Text textAlign="right" mt={6}>
          <Button
            variant="fab"
            color="primary"
            type="submit"
            isLoading={isFetching}
            disabled={isEmpty(mobilePrefix) || isEmpty(mobileNumber)}
            data-test-id={
              dataTestIdPrefix && `${dataTestIdPrefix}-submit-button`
            }
            data-testid={
              dataTestIdPrefix && `${dataTestIdPrefix}-submit-button`
            }
          >
            <Icon src={NextSvg} />
          </Button>
        </Text>
      </form>
    </Box>
  )
}

RequestOtpPage.propTypes = {
  variant: PropTypes.oneOf(["mobile_login", "bind_phone"]),
  mobilePrefix: PropTypes.string,
  mobileNumber: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array,
  dataTestIdPrefix: PropTypes.string,
  googleReCaptchaProps: PropTypes.shape({
    executeRecaptcha: PropTypes.func,
  }),
}

RequestOtpPage.defaultProps = {
  variant: "mobile_login",
}

const ReCaptchaContainer = (props) => {
  if (process.env.REACT_APP_RECAPTCHA_ENABLED === "true") {
    return (
      <GoogleReCaptchaConsumer>
        {(googleReCaptchaValues) => {
          return (
            <RequestOtpPage
              googleReCaptchaProps={googleReCaptchaValues}
              {...props}
            />
          )
        }}
      </GoogleReCaptchaConsumer>
    )
  }

  return <RequestOtpPage {...props} />
}

export default translate()(ReCaptchaContainer)
