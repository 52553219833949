import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { translate } from "react-i18next"
import { successDialogSelector } from "../../redux/selectors/dialog"
import Dialog, {
  Button,
  ButtonContainer,
  CoverImage,
  ContentContainer,
} from "../Dialog"
import Text from "../shared/Text"
import AccountMergeSucceedImg from "../../static/account-merge-succeed.png"
import { redirectToPostAccMergeCbUrl } from "../../redux/actions/controlFlow"

class SuccessDialog extends PureComponent {
  static propTypes = {
    redirectToPostAccMergeCbUrl: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isOpened: PropTypes.bool,
  }

  static defaultProps = {
    isOpened: false,
  }

  state = {
    isLoading: false,
  }

  btnOnClicked = () => {
    this.setState({
      isLoading: true,
    })
    this.props.redirectToPostAccMergeCbUrl()
  }

  render() {
    const { isOpened, t } = this.props
    return (
      <Dialog small isOpened={isOpened}>
        <ContentContainer>
          <CoverImage
            src={AccountMergeSucceedImg}
            width={"176px"}
            mobileWidth={"124px"}
            mb={4}
            alt={""}
          />
          <Text textAlign={"center"} variant={"dialog"} mt={[0, 5]}>
            {t("merge_account_success")}
            <br />
            {t("merge_account_success_redirection")
              .split("\n")
              .map((line, k) => (
                <Fragment key={k}>
                  {line}
                  <br />
                </Fragment>
              ))}
          </Text>
        </ContentContainer>
        <ButtonContainer>
          <Button
            color={"primary"}
            onClick={this.btnOnClicked}
            isLoading={this.state.isLoading}
          >
            {t("continue")}
          </Button>
        </ButtonContainer>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  isOpened: successDialogSelector(state).isOpened,
})

const mapDispatchToProps = {
  redirectToPostAccMergeCbUrl,
}

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SuccessDialog)
