import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import cleanElement from "clean-element"
import MaterialButton from "@material-ui/core/Button"
import { theme, space } from "styled-system"
import { ifProp } from "styled-tools"
import CircularProgress from "@material-ui/core/CircularProgress"

const CleanSpinner = cleanElement(CircularProgress)
CleanSpinner.propTypes = {
  isWhite: PropTypes.bool,
}

const ColoredSpinner = styled(CleanSpinner)`
  color: ${ifProp("isWhite", theme("colors.white"), theme("colors.primary"))};
`

const Button = props => {
  const { children, isLoading, className, disabled, ...restProps } = props
  const { variant } = restProps
  return (
    <div className={className}>
      <MaterialButton disabled={disabled || isLoading} {...restProps}>
        {isLoading ? (
          <ColoredSpinner size={24} isWhite={variant !== "text"} />
        ) : (
          children
        )}
      </MaterialButton>
    </div>
  )
}

const StyledButton = styled(Button)`
  a, button {
    width: ${ifProp({ variant: "fab" }, "40px", "183px")};
    height: 40px;
    padding: 0px;
    line-height: 1.71;
    ${space};

    &, &:focus {
      box-shadow: ${ifProp(
        { variant: "fab" },
        "0 2px 4px 0 rgba(26, 30, 40, 0.1)",
        "none",
      )};

    &:disabled {
      opacity: 0.48;
    }

    &:hover {
      &:disabled {
        background-color: ${theme("colors.primary")};
      }
    }
  }
`

Button.propTypes = {
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  variant: "text",
}

export default StyledButton
