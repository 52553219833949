import React from "react"
import { connect } from "react-redux"
import pick from "lodash/pick"
import { push } from "connected-react-router"

import {
  requestOtpRequest,
  verifyOtpRequest,
  mergeOtpRequest,
} from "../redux/actions/bind"
import { inputMobileOtp } from "../redux/actions/inputs"
import PageContainer from "../components/shared/PageContainer"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { BIND_REQUEST_OTP, BIND_VERIFY_OTP } from "../constants/apiRequests"
import { MOBILE_PREFIX, MOBILE_NUMBER, MOBILE_OTP } from "../constants/inputs"
import VerifyOtpPage from "../components/Mobile/VerifyOtpPage"
import ErrorDialog from "../components/MergeAccount/ErrorDialog"
import SuccessDialog from "../components/MergeAccount/SuccessDialog"

class BindPhoneVerifyOtp extends React.PureComponent {
  componentDidMount() {
    const { apiRequests, dispatch, match } = this.props
    // prevent direct access by url or wrong type
    if (
      apiRequests[BIND_REQUEST_OTP].successCount === 0 ||
      !["verify", "merge"].includes(match.params.type)
    ) {
      dispatch(push("/bindPhone"))
    }
  }
  onInputChanged = e => {
    const { value } = e.target
    const { dispatch } = this.props
    dispatch(inputMobileOtp(value))
  }
  dispatchRequestOtp = () => {
    const { inputs, match } = this.props
    const { dispatch } = this.props

    if (match.params.type === "verify") {
      dispatch(
        requestOtpRequest(`+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`),
      )
    } else {
      dispatch(mergeOtpRequest())
    }
  }
  dispatchVerifyOtp = () => {
    const { inputs, match } = this.props
    const { dispatch } = this.props
    dispatch(
      verifyOtpRequest(
        `+${inputs[MOBILE_PREFIX]}${inputs[MOBILE_NUMBER]}`,
        inputs[MOBILE_OTP],
        match.params.type === "verify" ? "bind" : "merge",
      ),
    )
  }
  render() {
    const { apiRequests, inputs } = this.props
    return (
      <PageContainer spreadContent>
        <VerifyOtpPage
          mobilePrefix={inputs[MOBILE_PREFIX]}
          mobileNumber={inputs[MOBILE_NUMBER]}
          mobileOtp={inputs[MOBILE_OTP]}
          isVerifying={apiRequests[BIND_VERIFY_OTP].isFetching}
          isResending={apiRequests[BIND_REQUEST_OTP].isFetching}
          onInputChanged={this.onInputChanged}
          onSubmit={this.dispatchVerifyOtp}
          resendOtp={this.dispatchRequestOtp}
          errors={apiRequests[BIND_VERIFY_OTP].errors}
          dataTestIdPrefix={"ssoPhoneBindOtp"}
        />
        <ErrorDialog />
        <SuccessDialog />
      </PageContainer>
    )
  }
}

export default connect(state => ({
  apiRequests: pick(state[API_STATE_KEY], [BIND_REQUEST_OTP, BIND_VERIFY_OTP]),
  inputs: pick(state[INPUT_STATE_KEY], [
    MOBILE_PREFIX,
    MOBILE_NUMBER,
    MOBILE_OTP,
  ]),
}))(BindPhoneVerifyOtp)
