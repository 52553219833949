import { applyMiddleware, createStore as createReduxStore } from "redux"
import createSagaMiddleware from "redux-saga"
import { connectRouter, routerMiddleware } from "connected-react-router"
import rootReducer, { initialState } from "./reducers"
import rootSaga from "./sagas"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

const sagaMiddleware = createSagaMiddleware()

export const createStore = history =>
  createReduxStore(
    connectRouter(history)(rootReducer), // new root reducer with router state
    initialState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  )

export const runSaga = () => {
  sagaMiddleware.run(rootSaga)
}
