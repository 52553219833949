import PropTypes from "prop-types"
import styled from "styled-components"
import cleanElement from "clean-element"
import { Box } from "grid-styled"
import { prop, ifProp, withProp } from "styled-tools"
import { theme, fontSize } from "styled-system"

const CleanBox = cleanElement(Box)
CleanBox.propTypes = {
  inline: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  variant: PropTypes.oneOf([
    "title",
    "subheading",
    "body2",
    "button",
    "display4",
    "dialog",
    "dialogTitle",
  ]),
}

const Text = styled(CleanBox)`
  display: ${ifProp("inline", "inline-block", "block")};
  text-align: ${prop("textAlign")};
  color: ${prop("color", theme("palette.common.text"))};
  ${withProp("variant", variant => theme(`typography.${variant}`))};
  /* add back fontSize helper to override the style from BaseTheme */
  ${fontSize};
`

Text.propTypes = CleanBox.propTypes

Text.defaultProps = {
  textAlign: "left",
}

export default Text
