import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import PropTypes from "prop-types"
import config from "../config"

const Provider = (props) => {
  if (process.env.REACT_APP_RECAPTCHA_ENABLED === "true") {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={config.reCaptchaSiteId}
        useEnterprise
        useRecaptchaNet
      >
        {props.children}
      </GoogleReCaptchaProvider>
    )
  }

  return props.children
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
