"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/* global window */
require("../vendor/piwik");
var assert = require("assert");

module.exports = function (_ref, sendPageView) {
  var trackingUrl = _ref.trackingUrl,
      siteId = _ref.siteId,
      userId = _ref.userId,
      _ref$isSPA = _ref.isSPA,
      isSPA = _ref$isSPA === undefined ? true : _ref$isSPA;

  assert(trackingUrl, "Piwik tracking url is missing");
  assert(siteId, "Piwik site id is missing");
  assert(userId, "Piwik user id is missing");

  var client = window.Piwik;
  var tracker = client.getTracker(trackingUrl, siteId);
  tracker.setUserId(userId);

  var setCustomDimension = function setCustomDimension() {
    var customDimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    Object.entries(customDimensions).map(function (row) {
      var _row = _slicedToArray(row, 2),
          key = _row[0],
          value = _row[1];

      tracker.setCustomDimension(key, value);
      if (key === 7) {
        // for piwik, dimension 7 and 9 are both member ID
        tracker.setCustomDimension(9, value);
      }
    });
  };

  function pageView(referrerUrl, currentUrl) {
    var customDimensions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    // track page view of Single page applications
    // https://matomo.org/blog/2017/02/how-to-track-single-page-websites-using-piwik-analytics/
    if (isSPA) {
      if (referrerUrl) {
        tracker.setReferrerUrl(referrerUrl);
      }
      if (currentUrl) {
        tracker.setCustomUrl(currentUrl);
      }
      tracker.setDocumentTitle(window.document.title);
      tracker.setGenerationTimeMs(0);
    }

    var params = {};
    Object.entries(customDimensions).map(function (row) {
      var _row2 = _slicedToArray(row, 2),
          key = _row2[0],
          value = _row2[1];

      params["dimension" + key] = value;
      if (row[0] === 7) {
        // for piwik, dimension 7 and 9 are both member ID
        params["dimension9"] = value;
      }
    });
    tracker.trackPageView(undefined, params);
    tracker.enableLinkTracking(true);
  }

  function fire() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        category = _ref2.category,
        action = _ref2.action,
        label = _ref2.label,
        value = _ref2.value,
        _ref2$customDimension = _ref2.customDimensions,
        customDimensions = _ref2$customDimension === undefined ? {} : _ref2$customDimension;

    var option = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var referrerUrl = option.referrerUrl,
        currentUrl = option.currentUrl;

    if (isSPA) {
      if (referrerUrl) {
        tracker.setReferrerUrl(referrerUrl);
      }
      if (currentUrl) {
        tracker.setCustomUrl(currentUrl);
      }
      tracker.setDocumentTitle(window.document.title);
      tracker.setGenerationTimeMs(0);
    }

    var params = {};
    Object.entries(customDimensions).map(function (row) {
      var _row3 = _slicedToArray(row, 2),
          key = _row3[0],
          value = _row3[1];

      params["dimension" + key] = value;
      if (row[0] === 7) {
        // for piwik, dimension 7 and 9 are both member ID
        params["dimension9"] = value;
      }
    });
    tracker.trackEvent(category, action, label, value, params);
  }

  // send page view immediately
  if (sendPageView) {
    pageView(); // calling pageView() on SSR doesn't need to provide old and current url
  }

  return {
    setCustomDimension: setCustomDimension,
    pageView: pageView,
    fire: fire,
    tracker: tracker
  };
};