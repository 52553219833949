import merge from "lodash/merge"
import { put, call, select } from "redux-saga/effects"

import * as api from "../../services/api"
import {
  sendAttributionSuccess,
  sendAttributionError,
} from "../actions/tracking"
import { accountIdSelector } from "../selectors/app"
import tracker, { createEvent } from "../../utils/tracker"

export function* sendAttribution({ attr }) {
  try {
    yield call(api.sendAttribution, attr)
    yield put(sendAttributionSuccess())
  } catch (error) {
    yield put(sendAttributionError(error.errors))
  }
}

export function* trackEvent({ event }) {
  try {
    const accountId = yield select(accountIdSelector)

    yield call(
      tracker.fire,
      createEvent(
        merge(event, accountId && { label: { account_id: accountId } }),
      ),
    )
  } catch (e) {}
}
