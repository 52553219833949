"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* global window */
if (typeof window === "undefined") {
  throw new Error("`data-tracker-client` should be run on browser environment only.");
}

require("core-js/fn/object/entries");
require("core-js/fn/object/values");
var gtag = require("./lib/gtag");
var piwikClient = require("./lib/piwik");
var assert = require("assert");

var _require = require("@hk01-digital/react-native-webview-events/cjs/web"),
    APP_ENUMS = _require.APP_ENUMS,
    EVENT_ENUMS = _require.EVENT_ENUMS,
    isSupportedApp = _require.isSupportedApp,
    requestAndWait = _require.requestAndWait;

module.exports = function () {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      GA = _ref.GA,
      Piwik = _ref.Piwik,
      webviewBridgeEnabled = _ref.webviewBridgeEnabled;

  var sendPageView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  assert(GA, "GA configuration is missing");
  assert(Piwik, "Piwik configuration is missing");
  webviewBridgeEnabled = webviewBridgeEnabled || false;

  var gaTracker = gtag(GA, sendPageView);
  var piwikTracker = piwikClient(Piwik, sendPageView);
  var currentPathname = ""; // url pathname of currently loaded page

  function setCustomDimension() {
    var customDimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    gaTracker.setCustomDimension(customDimensions);
    piwikTracker.setCustomDimension(customDimensions);
  }

  function pageView() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        GA = _ref2.GA,
        Piwik = _ref2.Piwik;

    var customDimensions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var location = arguments[2];
    var pathname = arguments[3];

    var newLocation = location ? location : window.location.href;
    var newPathname = pathname ? pathname : window.location.pathname + window.location.search + window.location.hash;
    if (GA) {
      gaTracker.pageView(newLocation, newPathname, customDimensions);
    }
    if (Piwik) {
      var _isSupportedApp;

      if (webviewBridgeEnabled && isSupportedApp((_isSupportedApp = {}, _defineProperty(_isSupportedApp, APP_ENUMS["01ICON"], ">=1.0.0"), _defineProperty(_isSupportedApp, APP_ENUMS["HK01"], ">=3.19.0"), _defineProperty(_isSupportedApp, APP_ENUMS["LETZGOAL"], ">=1.4.0"), _isSupportedApp))) {
        requestAndWait({
          type: EVENT_ENUMS.TRACK_PAGE_PAGEVIEW,
          payload: { screenName: newPathname, url: newLocation }
        });
      } else {
        piwikTracker.pageView(currentPathname, newPathname, customDimensions);
      }
    }
    currentPathname = newPathname;
  }

  function fire() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        GA = _ref3.GA,
        Piwik = _ref3.Piwik;

    var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var location = event.location,
        pathname = event.pathname;

    var newLocation = location ? location : window.location.href;
    var newPathname = pathname ? pathname : window.location.pathname + window.location.search + window.location.hash;
    if (GA) {
      gaTracker.fire(event);
    }
    if (Piwik) {
      var _isSupportedApp2;

      var label = event.label;
      if (webviewBridgeEnabled && isSupportedApp((_isSupportedApp2 = {}, _defineProperty(_isSupportedApp2, APP_ENUMS["01ICON"], ">=1.0.0"), _defineProperty(_isSupportedApp2, APP_ENUMS["HK01"], ">=3.19.0"), _defineProperty(_isSupportedApp2, APP_ENUMS["LETZGOAL"], ">=1.4.0"), _isSupportedApp2))) {
        // `label` could be: serialized JSON string, or plain non-JSON string
        try {
          // if `label` is a valid serialized JSON, parse the label as JSON object
          label = JSON.parse(label);
        } catch (e) {
          // if `label` is not a valid serialized JSON, silently ignore error and don't serialize it as JSON
        }
        requestAndWait({
          type: EVENT_ENUMS.TRACK_PAGE_EVENT,
          payload: {
            category: event.category,
            action: event.action,
            value: label,
            screenName: newPathname,
            url: newLocation
          }
        });
      } else {
        piwikTracker.fire(event, {
          referrerUrl: currentPathname,
          currentUrl: newPathname
        });
      }
    }
  }

  return {
    GA: gaTracker.tracker,
    Piwik: piwikTracker.tracker,
    setCustomDimension: setCustomDimension,
    pageView: pageView,
    fire: fire
  };
};