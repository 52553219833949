import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box } from "grid-styled"
import { ifProp } from "styled-tools"
import { translate, Interpolate } from "react-i18next"
import Paper from "@material-ui/core/Paper"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"
import PageGrid from "../grid/PageGrid"
import { space, theme } from "styled-system"
import Text from "./Text"
import TextLink from "./TextLink"
import cleanElement from "clean-element"
import config from "../../config"

const cleanPaper = cleanElement(Paper)
cleanPaper.propTypes = {
  spreadContent: PropTypes.bool,
}

const StyledPaper = styled(cleanPaper)`
  display: flex;
  flex-direction: column;
  justify-content: ${ifProp("spreadContent", "space-between", "normal")};
  width: 100%;
  min-height: 464px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: ${theme("palette.page.backgroundImage")};
  ${space};
  ${MediaQuery.lessThan(TABLET)`
    box-shadow: none;
    background: none;
    min-height: 0;
    justify-content: normal;
  `};
`

const FootnoteContainer = styled(Box)`
  min-height: 32px;
  ${MediaQuery.lessThan(TABLET)`
    min-height: 0;
  `};
`

const PageContainer = ({ children, showFootnote, spreadContent, t }) => (
  <PageGrid flexWrap="wrap">
    <StyledPaper py={[6]} px={[5, 6]} spreadContent={spreadContent}>
      {children}
    </StyledPaper>
    <FootnoteContainer mt={[0, 6]} mb={[0, 4]} px={[5, 6]}>
      {showFootnote && (
        <Text
          mt={[6, 0]}
          mb={[4, 0]}
          f={0}
          textAlign="center"
          variant="display4"
        >
          <Interpolate
            i18nKey={"footer"}
            terms={
              <TextLink
                href={`${config.hk01BaseUrl}/terms`}
                data-test-id={"ssoLogin-tnc-link"}
                data-testid={"ssoLogin-tnc-link"}
              >
                {t("terms")}
              </TextLink>
            }
            privacy={
              <TextLink
                href={`${config.hk01BaseUrl}/privacy`}
                data-test-id={"ssoLogin-privacy-link"}
                data-testid={"ssoLogin-privacy-link"}
              >
                {t("privacy")}
              </TextLink>
            }
            collectPersonalDataStatement={
              <TextLink
                href={`${config.hk01BaseUrl}/privacy`}
                data-test-id={"ssoLogin-collect-personal-data-statement-link"}
                data-testid={"ssoLogin-collect-personal-data-statement-link"}
              >
                {t("collect_personal_data_statement")}
              </TextLink>
            }
          />
        </Text>
      )}
    </FootnoteContainer>
  </PageGrid>
)

PageContainer.propTypes = {
  showFootnote: PropTypes.bool,
  spreadContent: PropTypes.bool,
}

PageContainer.defaultProps = {
  showFootnote: false,
  spreadContent: false,
}

export default translate()(PageContainer)
