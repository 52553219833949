import React from "react"
import styled from "styled-components"
import { Flex } from "grid-styled"
import { theme } from "styled-system"

const StyledDivider = styled(Flex)`
  color: ${theme("colors.grey48")};
  &::before,
  &::after {
    content: "";
    display: inline-block;
    border-top: 2px solid #e6e7e8;
    width: 56px;
    margin: 0 16px;
    transform: translateY(50%);
  }
`

const TextDivider = ({ children, ...restProps }) => (
  <StyledDivider {...restProps} justifyContent="center" f={0}>
    {children}
  </StyledDivider>
)

export default TextDivider
