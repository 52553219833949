import pick from "lodash/pick"
import { put, select, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"

import * as api from "../../services/api"
import {
  requestOtpSuccess,
  requestOtpError,
  resetPasswordSuccess,
  resetPasswordError,
} from "../actions/resetPassword"
import { STATE_KEY as APP_STATE_KEY } from "../reducers/app"

export function* requestOtp({ email }) {
  const { appInfo, callbackUrl, isBindPhoneRequired } = yield select(state =>
    pick(state[APP_STATE_KEY], [
      "appInfo",
      "callbackUrl",
      "isBindPhoneRequired",
    ]),
  )
  try {
    yield call(api.requestOtp, "email", email, callbackUrl, "forget_password", {
      appId: appInfo.appId,
      bindPhone: isBindPhoneRequired,
    })
    yield put(requestOtpSuccess())
    yield put(push(urlJoin("forgotPassword", "otpSent")))
  } catch (error) {
    yield put(requestOtpError(error.errors))
  }
}

export function* resetPassword({ token, newPassword }) {
  try {
    yield call(api.resetPassword, token, newPassword)
    yield put(resetPasswordSuccess())
    yield put(push(urlJoin("member", "resetPassword", "success")))
  } catch (error) {
    yield put(resetPasswordError(error.errors))
  }
}
