import React from "react"
import { Route, Switch } from "react-router-dom"
import urlJoin from "proper-url-join"

import EmailLogin from "../pages/EmailLogin"
import EmailPassword from "../pages/EmailPassword"
import EmailOtpSent from "../pages/EmailOtpSent"
import MobileLogin from "../pages/MobileLogin"
import MobileVerifyOtp from "../pages/MobileVerifyOtp"
import NotFound from "../pages/NotFound"

const LoginRoutes = ({ match }) => (
  <Switch>
    <Route path={urlJoin(match.url, "email")} component={EmailLogin} exact />
    <Route
      path={urlJoin(match.url, "email", "otp")}
      component={EmailOtpSent}
      exact
    />
    <Route
      path={urlJoin(match.url, "email", "password")}
      component={EmailPassword}
    />
    <Route path={urlJoin(match.url, "mobile")} component={MobileLogin} exact />
    <Route
      path={urlJoin(match.url, "mobile", "verify")}
      component={MobileVerifyOtp}
    />
    <Route component={NotFound} />
  </Switch>
)

export default LoginRoutes
