export const BIND_REQUEST_OTP = "BIND/REQUEST_OTP"
export const BIND_VERIFY_OTP = "BIND/VERIFY_OTP"

export const BIND_MERGE_OTP_REQUEST = "BIND/MERGE_OTP"

export const EMAIL_REQUEST_OTP = "EMAIL/REQUEST_OTP"
export const EMAIL_VERIFY_OTP = "EMAIL/VERIFY_OTP"
export const EMAIL_AUTHENTICATE = "EMAIL/AUTHENTICATE"

export const MOBILE_REQUEST_OTP = "MOBILE/REQUEST_OTP"
export const MOBILE_VERIFY_OTP = "MOBILE/VERIFY_OTP"

export const APP_GET_LOGIN_STATUS = "APP/GET_LOGIN_STATUS"
export const APP_GET_APP_INFO = "APP/GET_APP_INFO"

export const RESET_PASSWORD_REQUEST_OTP = "RESET_PASSWORD/REQUEST_OTP"
export const RESET_PASSWORD_RESET_PASSWORD = "RESET_PASSWORD/RESET_PASSWORD"

export const TRACKING_SEND_ATTRIBUTION = "TRACKING/SEND_ATTRIBUTION"
