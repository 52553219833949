import * as API_REQUESTS from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

const STATE_KEY = "apiRequests"

const initialState = Object.values(API_REQUESTS).reduce(
  (state, apiName) => ({
    ...state,
    ...{
      [apiName]: {
        successCount: 0,
        isFetching: false,
        errors: [],
      },
    },
  }),
  {},
)

// construct the pattern for regex match
const apiRequestsPattern = Object.values(API_REQUESTS).join("|")
const validActionPattern = new RegExp(
  `^(${apiRequestsPattern})/(${REQUEST}|${SUCCESS}|${ERROR})$`,
)

const reducer = (state = {}, action) => {
  const match = validActionPattern.exec(action.type)
  if (match) {
    const [, apiName, matchedAction] = match

    switch (matchedAction) {
      case REQUEST:
        return {
          ...state,
          [apiName]: {
            successCount: state[apiName].successCount,
            isFetching: true,
            errors: [],
          },
        }

      case SUCCESS:
        return {
          ...state,
          [apiName]: {
            successCount: state[apiName].successCount + 1,
            isFetching: false,
            errors: [],
          },
        }

      case ERROR:
        return {
          ...state,
          [apiName]: {
            successCount: state[apiName].successCount,
            isFetching: false,
            errors: action.errors,
          },
        }

      default:
        return state
    }
  } else {
    return state
  }
}

export default reducer
export { STATE_KEY, initialState }
