import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import pick from "lodash/pick"
import isEmpty from "lodash/isEmpty"
import { translate } from "react-i18next"
import { Box } from "grid-styled"

import { EMAIL } from "../constants/inputs"
import { RESET_PASSWORD_REQUEST_OTP } from "../constants/apiRequests"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { requestOtpRequest } from "../redux/actions/resetPassword"
import { inputEmail } from "../redux/actions/inputs"
import Button from "../components/shared/Button"
import PageContainer from "../components/shared/PageContainer"
import Text from "../components/shared/Text"
import TextField from "../components/shared/TextField"

class ForgotPassword extends React.PureComponent {
  onInputChanged = (e) => {
    const { value } = e.target
    const { dispatch } = this.props

    dispatch(inputEmail(value))
  }

  onConfirmButtonClicked = () => {
    const { dispatch, inputs } = this.props
    dispatch(requestOtpRequest(inputs[EMAIL]))
  }

  render() {
    const { apiRequests, inputs, t } = this.props
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("forgot_password")}
          </Text>
          <Text variant="subheading" mb={7}>
            {t("forgot_password_desc")}
          </Text>
          <TextField
            mb={3}
            type={"email"}
            name={EMAIL}
            value={inputs[EMAIL]}
            placeholder={t("email")}
            onChange={this.onInputChanged}
            errors={apiRequests[RESET_PASSWORD_REQUEST_OTP].errors}
            disabled={apiRequests[RESET_PASSWORD_REQUEST_OTP].isFetching}
            fullWidth
            withClear
            autoFocus
            data-test-id={"ssoPwResetEmail-email-inputEmail"}
            data-testid={"ssoPwResetEmail-email-inputEmail"}
          />
        </Box>
        <Box>
          <Text textAlign="center" mt={6}>
            <Button
              mb={2}
              variant="contained"
              color="primary"
              isLoading={apiRequests[RESET_PASSWORD_REQUEST_OTP].isFetching}
              onClick={this.onConfirmButtonClicked}
              disabled={isEmpty(inputs[EMAIL])}
              data-test-id={"ssoPwResetEmail-submit-button"}
              data-testid={"ssoPwResetEmail-submit-button"}
            >
              {t("confirm")}
            </Button>
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  connect((state) => ({
    apiRequests: pick(state[API_STATE_KEY], [RESET_PASSWORD_REQUEST_OTP]),
    inputs: pick(state[INPUT_STATE_KEY], [EMAIL]),
  })),
  translate(),
)(ForgotPassword)
