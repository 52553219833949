import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import pick from "lodash/pick"
import { authenticateRequest } from "../redux/actions/email"
import { inputPassword } from "../redux/actions/inputs"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { EMAIL, PASSWORD } from "../constants/inputs"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { EMAIL_AUTHENTICATE } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import { Box } from "grid-styled"
import { translate } from "react-i18next"
import Text from "../components/shared/Text"
import TextField from "../components/shared/TextField"
import Button from "../components/shared/Button"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"
import isEmpty from "lodash/isEmpty"

class EmailPassword extends React.PureComponent {
  componentDidMount() {
    const { dispatch, inputs } = this.props
    if (isEmpty(inputs[EMAIL])) {
      dispatch(push(urlJoin("by", "email")))
    }
  }

  onInputChanged = (e) => {
    const { value } = e.target
    const { dispatch } = this.props

    dispatch(inputPassword(value))
  }

  onLoginButtonClicked = () => {
    const { dispatch, inputs } = this.props
    dispatch(authenticateRequest(inputs[EMAIL], inputs[PASSWORD]))
  }

  onForgotPasswordButtonClicked = () => {
    const { dispatch } = this.props
    dispatch(push("/forgotPassword"))
  }

  render() {
    const { apiRequests, inputs, t } = this.props
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("password")}
          </Text>
          <Text variant="subheading" mb={7}>
            {t("email_password_desc")}
          </Text>
          <TextField
            mb={3}
            type={"password"}
            name={PASSWORD}
            value={inputs[PASSWORD]}
            placeholder={t("password")}
            onChange={this.onInputChanged}
            errors={apiRequests[EMAIL_AUTHENTICATE].errors}
            disabled={apiRequests[EMAIL_AUTHENTICATE].isFetching}
            fullWidth
            withClear
            autoFocus
            data-test-id={"ssoEmailPassword-password-inputPassword"}
            data-testid={"ssoEmailPassword-password-inputPassword"}
          />
        </Box>
        <Box>
          <Text textAlign="center" mt={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.onLoginButtonClicked}
              isLoading={apiRequests[EMAIL_AUTHENTICATE].isFetching}
              mb={2}
              data-test-id={"ssoEmailPassword-login-button"}
              data-testid={"ssoEmailPassword-login-button"}
            >
              {t("login")}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={this.onForgotPasswordButtonClicked}
              data-test-id={"ssoEmailPassword-forgotPassword-button"}
              data-testid={"ssoEmailPassword-forgotPassword-button"}
            >
              {t("forgot_password")}
            </Button>
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect((state) => ({
    apiRequests: pick(state[API_STATE_KEY], [EMAIL_AUTHENTICATE]),
    inputs: pick(state[INPUT_STATE_KEY], [EMAIL, PASSWORD]),
  })),
)(EmailPassword)
