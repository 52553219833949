import castArray from "lodash/castArray"
import {
  APP_GET_APP_INFO,
  APP_GET_LOGIN_STATUS,
} from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const GET_APP_INFO_REQUEST = `${APP_GET_APP_INFO}/${REQUEST}`
export const GET_APP_INFO_SUCCESS = `${APP_GET_APP_INFO}/${SUCCESS}`
export const GET_APP_INFO_ERROR = `${APP_GET_APP_INFO}/${ERROR}`

export const GET_LOGIN_STATUS_REQUEST = `${APP_GET_LOGIN_STATUS}/${REQUEST}`
export const GET_LOGIN_STATUS_SUCCESS = `${APP_GET_LOGIN_STATUS}/${SUCCESS}`
export const GET_LOGIN_STATUS_ERROR = `${APP_GET_LOGIN_STATUS}/${ERROR}`

export const VERIFY_APP_INFO = "VERIFY_APP_INFO"
export const SET_CALLBACK_URL = "SET_CALLBACK_URL"
export const SET_BIND_PHONE_OPTION = "SET_BIND_PHONE_OPTION"

// for errors that make the flow cannot be continued
export const SET_APP_ERRORS = "SET_APP_ERRORS"

export const verifyAppInfo = (appId, callbackUrl) => ({
  type: VERIFY_APP_INFO,
  appId,
  callbackUrl,
})

export const getAppInfoRequest = (appId) => ({
  type: GET_APP_INFO_REQUEST,
  appId,
})

export const getAppInfoSuccess = (appInfo) => ({
  type: GET_APP_INFO_SUCCESS,
  appInfo,
})

export const getAppInfoError = (errors) => ({
  type: GET_APP_INFO_ERROR,
  errors,
})

export const getLoginStatusRequest = ({ callbackUrl, appId }) => ({
  type: GET_LOGIN_STATUS_REQUEST,
  payload: {
    callbackUrl,
    appId,
  },
})

export const getLoginStatusSuccess = (status) => ({
  type: GET_LOGIN_STATUS_SUCCESS,
  status,
})

export const getLoginStatusError = (errors) => ({
  type: GET_LOGIN_STATUS_ERROR,
  errors,
})

export const setCallbackUrl = (url) => ({
  type: SET_CALLBACK_URL,
  url,
})

export const setBindPhoneOption = (option) => ({
  type: SET_BIND_PHONE_OPTION,
  option,
})

export const setAppErrors = (errors) => ({
  type: SET_APP_ERRORS,
  errors: castArray(errors),
})
