import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Flex } from "grid-styled"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"

const StyledFlex = styled(Flex)`
  width: 100%;
  height: 52px;
  border-top: 1px solid rgb(230, 231, 232);

  ${MediaQuery.lessThan(TABLET)`
    height: 44px;
  `};
`

const ButtonContainer = ({ children }) => (
  <StyledFlex
    flexDirection={"row"}
    justifyContent={"space-around"}
    alignItems={"stretch"}
  >
    {children}
  </StyledFlex>
)

ButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonContainer
