import pick from "lodash/pick"
import { put, select, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import urlJoin from "proper-url-join"

import * as api from "../../services/api"
import {
  authenticateSuccess,
  authenticateError,
  requestOtpSuccess,
  requestOtpError,
  verifyOtpSuccess,
  verifyOtpError,
} from "../actions/email"
import { setCallbackUrl, setAppErrors } from "../actions/app"
import { loginSuccess, verifyAppInfoSuccess } from "../actions/controlFlow"
import { STATE_KEY as APP_STATE_KEY } from "../reducers/app"

export function* authenticate({ email, password }) {
  try {
    const { appInfo, callbackUrl, isBindPhoneRequired } = yield select(
      state => state[APP_STATE_KEY],
    )

    const { response } = yield call(api.authenticate, {
      method: "email",
      authId: email,
      authSecret: password,
      appId: appInfo ? appInfo.appId : undefined,
      redirectUrl: callbackUrl,
      bindPhone: isBindPhoneRequired ? 1 : 0,
    })
    yield put(setCallbackUrl(response.redirectUrl))

    yield put(authenticateSuccess())
    yield put(loginSuccess())
  } catch (error) {
    yield put(authenticateError(error.errors))
  }
}

export function* requestOtp({ email }) {
  const { appInfo, callbackUrl, isBindPhoneRequired } = yield select(state =>
    pick(state[APP_STATE_KEY], [
      "appInfo",
      "callbackUrl",
      "isBindPhoneRequired",
    ]),
  )
  try {
    yield call(api.requestOtp, "email", email, callbackUrl, "authenticate", {
      appId: appInfo.appId,
      bindPhone: isBindPhoneRequired,
    })
    yield put(requestOtpSuccess())
    yield put(push(urlJoin("by", "email", "otp")))
  } catch (error) {
    yield put(requestOtpError(error.errors))
  }
}

export function* verifyOtp({ token }) {
  try {
    const { appInfo, isBindPhoneRequired } = yield select(
      state => state[APP_STATE_KEY],
    )

    const { response } = yield call(api.verifyOtp, {
      method: "email",
      token,
      authId: "",
      action: "authenticate",
      appId: appInfo ? appInfo.appId : undefined,
      bindPhone: isBindPhoneRequired ? 1 : 0,
    })
    yield put(setCallbackUrl(response.redirectUrl))

    yield put(verifyOtpSuccess())
    // the `callbackUrl` is responsed from server, should be no need to verify,
    // send `VERIFY_APP_INFO_SUCCESS` to let the `APP_INITIALIZED` change to `true`
    yield put(verifyAppInfoSuccess())
    yield put(loginSuccess())
  } catch (error) {
    yield put(verifyOtpError(error.errors))
    // Set app errors if failed in here
    yield put(setAppErrors(error.errors))
  }
}
