import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import styled from "styled-components"
import { translate } from "react-i18next"
import get from "lodash/get"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import Flex from "grid-styled/dist/Flex"
import { Checkbox, Snackbar } from "@material-ui/core"
import Text from "../shared/Text"
import AccountMergeImg from "../../static/account-merge.png"
import Dialog, {
  Button,
  ButtonContainer,
  ButtonDivider,
  CoverImage,
  ContentContainer,
} from "../Dialog"
import { displayInfomationDialog } from "../../redux/actions/dialog"
import { mergeOtpRequest, mergeOtpSuccess } from "../../redux/actions/bind"
import { informationDialogSelector } from "../../redux/selectors/dialog"
import { STATE_KEY as API_STATE_KEY } from "../../redux/reducers/apiRequests"
import { BIND_MERGE_OTP_REQUEST } from "../../constants/apiRequests"
import { trackEvent } from "../../redux/actions/tracking"

const Note = styled(Text).attrs({ variant: "display4", mt: [2], mb: [4] })`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;

  &::before {
    position: absolute;
    left: -12px;
    top: 0;
    height: 100%;
    display: inline-block;
    font-family: "PingFangHK-Semibold";
    font-size: 16px;
    line-height: 16px;
    color: rgb(255, 73, 92);
    content: "*";
  }
`

const TNCContainer = styled.div`
  padding: 4px 9px;
  width: 100%;
  height: 157px;
  border: 1px solid rgb(151, 151, 151);
  color: rgba(26, 30, 40, 0.6);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  resize: none;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(216, 216, 216);
    border-radius: 6px;
  }

  & ol {
    margin: 0;
    padding: 0 0 0 18px;
  }
`

const AgreementContainer = styled(Flex).attrs({ flexDirection: "row" })`
  color: #0033ee;
  font-size: 14px;
  line-height: 20px;

  & span {
    color: #0033ee;
  }

  & > label {
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 0;
    padding-left: 14px;
    cursor: pointer;
    user-select: none;
  }
`

const StyledCheckbox = styled(Checkbox)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: rgb(0, 51, 238);
  flex-shrink: 0;

  & > input {
    width: 24px;
    height: 24px;
  }
`

class InformationDialog extends PureComponent {
  state = {
    isCheckboxChecked: false,
  }

  onCheckboxChanged = () => {
    this.setState((state) => ({
      isCheckboxChecked: !state.isCheckboxChecked,
    }))
  }

  onMergeButtonClicked = (e) => {
    const { sendMergeRequest, trackEvent } = this.props

    trackEvent({
      action: "click_merge",
      label: {
        item_text: e.target.innerText.trim(),
      },
    })

    sendMergeRequest()
  }

  handleBackButtonClick = (e) => {
    const { onClose, trackEvent } = this.props

    trackEvent({
      action: "click_merge",
      label: {
        item_text: e.target.innerText.trim(),
      },
    })

    return onClose()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isOpened !== this.props.isOpened &&
      this.props.isOpened === false
    ) {
      this.setState({
        isCheckboxChecked: false,
      })
    }
  }

  render() {
    const {
      isOpened,
      onClose,
      isLoading,
      isSnackbarOpened,
      onSnackbarClose,
      t,
    } = this.props

    return (
      <Fragment>
        <Dialog {...{ isOpened, onClose }}>
          <ContentContainer>
            <CoverImage
              src={AccountMergeImg}
              width={"194px"}
              mobileWidth={"120px"}
              alt={""}
            />
            <Text variant={"dialogTitle"}>{t("merge_account_title")}</Text>
            <TNCContainer>
              「合併帳戶」條款及細則
              <br />
              <br />
              <ol>
                <li>「合併帳戶」功能需要由會員自行啟動。</li>
                <li>
                  發起「合併帳戶」功能之會員於成功驗證確認持有合併的帳戶後，方可以進行合併帳戶手續。
                </li>
                <li>
                  進行合併帳戶時，必須使用香港手機短訊驗證以確定帳戶控制權，會員有責任並必須採取適當安全措施以防範任何第三者取得用作驗証的手機短訊。
                </li>
                <li>
                  進行合併帳戶後，以電話號碼原有綁訂註冊之帳戶將保留並合併以下未綁定電話的帳戶資料；包括
                  <ol type={"a"}>
                    <li>
                      登入帳戶方式
                      <ol type={"i"}>
                        <li>電郵</li>
                        <li>Facebook帳戶 </li>
                        <li>
                          Google 帳戶
                          <br />
                          備註:
                          如未綁定電話的帳戶擁有以上登入的資料，其資料會取代已綁定電話的帳戶。原有以電話號碼原有綁訂註冊之帳戶的登入資料將不便保留。
                        </li>
                      </ol>
                    </li>
                    <li>未使用之電子票</li>
                  </ol>
                </li>
                <li>
                  除項目四以外之資料，系統只會保留以電話號碼原有綁訂註冊之帳戶資料（如適用），包括已綁定之電郵、Facebook帳戶、頭像、個人資料、推廣設定、積分、積分紀錄、錢包設定、通知中心訊息、我的收藏、推薦朋友鏈結及開講發文記錄。
                </li>
                <li>帳戶一經成功合併後，所有原有帳戶的資料將無法復原。</li>
                <li>
                  如合併帳戶過程有任何資料遺失或錯誤，香港01並不會作任何補償，香港01亦不會負責任何損失，會員對香港01不具有任何追究權力。
                </li>
                <li>會員須自行評估是否啟動「合併帳戶」功能。</li>
                <li>
                  香港01並無任何責任查証需要進行合併的各帳戶是否由同一人所持有。
                </li>
                <li>
                  香港01的條款及細則(https://www.hk01.com/terms)與私隱政策聲明(https://www.hk01.com/privacy)適用於合併後的帳戶。
                </li>
                <li>如有爭議，HK01保留最終決定權。 </li>
              </ol>
            </TNCContainer>
            <Note>{t("merge_account_note")}</Note>

            <AgreementContainer>
              <StyledCheckbox
                id={"tnc_confirm"}
                icon={
                  <CheckBoxOutlineBlankIcon
                    fontSize={"inherit"}
                    color={"inherit"}
                  />
                }
                checkedIcon={
                  <CheckBoxIcon fontSize={"inherit"} color={"inherit"} />
                }
                checked={this.state.isCheckboxChecked}
                onChange={this.onCheckboxChanged}
                disableRipple
              />
              <label htmlFor={"tnc_confirm"}>
                {t("merge_account_agreement")}
              </label>
            </AgreementContainer>
          </ContentContainer>
          <ButtonContainer>
            <Button onClick={this.handleBackButtonClick}>{t("back")}</Button>
            <ButtonDivider />
            <Button
              color={"primary"}
              onClick={this.onMergeButtonClicked}
              disabled={!this.state.isCheckboxChecked}
              isLoading={isLoading}
            >
              {t("merge_account")}
            </Button>
          </ButtonContainer>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={isSnackbarOpened}
          autoHideDuration={3000}
          onClose={onSnackbarClose}
          message={t("errors.SERVER_BUSY_PLEASE_RETRY")}
        />
      </Fragment>
    )
  }
}

InformationDialog.defaultProps = {
  isLoading: false,
  isSnackbarOpened: false,
  sendMergeRequest: () => {},
  onSnackbarClose: () => {},
}

InformationDialog.propTypes = {
  isLoading: PropTypes.bool,
  isSnackbarOpened: PropTypes.bool,
  sendMergeRequest: PropTypes.func,
  onSnackbarClose: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isOpened: informationDialogSelector(state).isOpened,
  isLoading: get(state, [API_STATE_KEY, BIND_MERGE_OTP_REQUEST, "isFetching"]),
  isSnackbarOpened:
    get(state, [API_STATE_KEY, BIND_MERGE_OTP_REQUEST, "errors"]).length > 0,
})

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(displayInfomationDialog(false))
    dispatch(mergeOtpSuccess())
  },
  sendMergeRequest: () => dispatch(mergeOtpRequest()),
  onSnackbarClose: () => dispatch(mergeOtpSuccess()),
  trackEvent: (event) => dispatch(trackEvent(event)),
})

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
)(InformationDialog)
