import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import InputAdornment from "@material-ui/core/InputAdornment"
import isEmpty from "lodash/isEmpty"
import { ifProp } from "styled-tools"
import Icon from "./Icon"
import { CloseSvg } from "../../static/icons"
import { theme, space } from "styled-system"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import FormHelperText from "@material-ui/core/FormHelperText"
import { MOBILE_PREFIX, MOBILE_NUMBER } from "../../constants/inputs"
import { translate } from "react-i18next"
import cleanElement from "clean-element"

const CleanFormControl = cleanElement(FormControl)
CleanFormControl.propTypes = {
  i18n: PropTypes.object,
  tReady: PropTypes.bool,
}

const StyledFormControl = styled(CleanFormControl)`
  ${space};

  p {
    font-size: 14px;
    line-height: 1.14;
    color: #ff495c;
  }
`

const StyledInput = styled(Input)`
  input {
    min-height: 24px;
    padding: 0;
    margin: 8px 0;
    line-height: 1.5;
    color: ${theme("palette.common.text")};
    caret-color: #0033ee;
    align-self: flex-end;
  }

  input::placeholder,
  input:disabled {
    color: rgba(26, 30, 40, 0.48);
  }

  &:hover::before {
    border-bottom-width: 1px !important;
  }

  &::after {
    border-bottom-width: 1px;
    border-bottom-color: ${ifProp("error", "#ff495c", "#1745ef")};
  }
`

const PrefixTextField = styled(StyledInput)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "+";
    display: block;
    min-height: 24px;
    line-height: 1.5;
    color: ${theme("palette.common.text")};
    font-size: 16px;
  }

  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 24px;
    opacity: 0.2;
    background-color: #1a1e28;
    margin: 0 16px;
  }

  & > input {
    width: 31px;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 0;
`

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

class PhoneField extends React.PureComponent {
  setInputRef = (input) => {
    this.textInput = input
  }

  fireChangeEvent = (context, newValue) => {
    const setValue = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value",
    ).set
    const event = new Event("input", { bubbles: true })
    setValue.call(context, newValue)
    context.dispatchEvent(event)
  }

  handleClear = (e) => {
    this.fireChangeEvent(this.textInput, "")
    this.textInput.focus()
  }

  render() {
    const {
      errors,
      withClear,
      onChange,
      fullWidth,
      disabled,
      mobilePrefixValue,
      mobileNumberValue,
      t,
      autoFocus,
      dataTestIdPrefix,
      ...restProps
    } = this.props

    return (
      <StyledFormControl fullWidth={fullWidth} {...restProps}>
        <StyledInput
          inputRef={this.setInputRef}
          type={"tel"}
          name={MOBILE_NUMBER}
          placeholder={t("mobile_phone")}
          value={mobileNumberValue}
          error={!isEmpty(errors)}
          onChange={onChange}
          disabled={disabled}
          autoFocus={autoFocus}
          inputProps={{
            "data-test-id":
              dataTestIdPrefix && `${dataTestIdPrefix}-number-inputTel`,
            "data-testid":
              dataTestIdPrefix && `${dataTestIdPrefix}-number-inputTel`,
          }}
          startAdornment={
            <PrefixTextField
              inputProps={{
                maxLength: "3",
                "data-test-id":
                  dataTestIdPrefix && `${dataTestIdPrefix}-prefix-inputTel`,
                "data-testid":
                  dataTestIdPrefix && `${dataTestIdPrefix}-prefix-inputTel`,
              }}
              type={"tel"}
              name={MOBILE_PREFIX}
              placeholder={t("mobile_prefix")}
              value={mobilePrefixValue}
              onChange={onChange}
              disabled={disabled}
              disableUnderline
            />
          }
          endAdornment={
            withClear &&
            !this.props.disabled && (
              <StyledInputAdornment position={"end"}>
                <StyledIcon
                  src={CloseSvg}
                  width={"16px"}
                  height={"16px"}
                  onClick={this.handleClear}
                />
              </StyledInputAdornment>
            )
          }
        />
        {!isEmpty(errors) && (
          <FormHelperText
            error
            data-testid={
              dataTestIdPrefix && `${dataTestIdPrefix}-number-textError`
            }
          >
            {errors.map((error, i) => (
              <Fragment key={i}>
                {t(`errors.${error}`)}
                <br />
              </Fragment>
            ))}
          </FormHelperText>
        )}
      </StyledFormControl>
    )
  }
}

PhoneField.propTypes = {
  errors: PropTypes.array,
  autoFocus: PropTypes.bool,
  withClear: PropTypes.bool,
  onChange: PropTypes.func,
  mobilePrefixValue: PropTypes.string,
  mobileNumberValue: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
}

PhoneField.defaultProps = {
  errors: [],
  autoFocus: false,
  withClear: false,
}

export default translate()(PhoneField)
