const defaultConfig = {
  apiBaseUrl: "/api/v1.0/sso",
  thirdPartyLoginBaseUrl: `${process.env.BACKEND_ORIGIN}/thirdParty`,
  hk01BaseUrl: process.env.REACT_APP_HK01_WEB_BASE_URL,
  reCaptchaSiteId: process.env.REACT_APP_RECAPTCHA_SITE_ID,
}

let config = defaultConfig
switch (process.env.NODE_ENV) {
  case "development":
    config = {
      ...defaultConfig,
      ...require("./config.development").default,
    }
    break

  case "staging":
    config = {
      ...defaultConfig,
      ...require("./config.staging").default,
    }
    break

  case "production":
    config = {
      ...defaultConfig,
      ...require("./config.production").default,
    }
    break

  default:
    break
}

export default config
