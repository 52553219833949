import BaseTheme from "./BaseTheme"

// global css rules
const globalCss = {
  "@global": {
    "*": {
      boxSizing: "border-box",
    },
    "html, body": {
      height: "100%", // so that the background fills the whole screen on mobile
    },
    html: {
      fontFamily: BaseTheme.font,
      fontSize: BaseTheme.rootFontSize,
    },
    body: {
      backgroundColor: BaseTheme.palette.page.background,
      backgroundImage: BaseTheme.palette.page.backgroundImage,
      backgroundAttachment: "fixed",
    },
    input: {
      "&::-ms-clear": {
        display: "none",
      },
    },
  },
}

export default globalCss
