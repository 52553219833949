import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box } from "grid-styled"

const SvgContainer = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    height: 100%;
  }
`

const Icon = ({ src, width, height, ...props }) => {
  return (
    <SvgContainer style={{ fontSize: height, width, height }} {...props}>
      {typeof src === "string" ? <img src={src} alt={src} /> : src}
    </SvgContainer>
  )
}

Icon.propTypes = {
  src: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

Icon.defaultProps = {
  height: "24px",
}

export default Icon
