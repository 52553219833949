import styled from "styled-components"
import { space } from "styled-system"
import { ifProp, prop } from "styled-tools"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"

const CoverImage = styled.img`
  display: block;
  margin: 0 auto;
  ${space};
  ${MediaQuery.lessThan(TABLET)`
    width: ${ifProp("mobileWidth", prop("mobileWidth"), prop("width"))};
  `};
`

export default CoverImage
