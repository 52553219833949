import styled from "styled-components"
import Button from "../shared/Button"

const DialogButton = styled(Button)`
  padding-bottom: 0;
  flex-grow: 1;
  display: flex;

  & > a,
  & > button {
    width: auto;
    height: auto;
    margin: 0;
    flex: 1 0 auto;
    border-radius: 0;
  }
`

export default DialogButton
