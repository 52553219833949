import styled from "styled-components"
import { Flex } from "grid-styled"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"

const ContentContainer = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
})`
  height: auto;
  padding: 23px 22px 24px;
  flex: 1 0 auto;

  ${MediaQuery.lessThan(TABLET)`
    padding: 16px 12px 16px;
  `};

  & * {
    max-width: 100%;
  }
`

export default ContentContainer
