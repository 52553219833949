import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { ThemeProvider } from "styled-components"
import { createStore, runSaga } from "./redux"
import App from "./App"
import routes from "./routes"
import theme from "./theme/BaseTheme"
import MuiThemeProvider from "./theme/MuiThemeProvider"
import JssProvider from "./theme/JssProvider"

import history from "./routes/history"

const store = createStore(history)
runSaga()

ReactDOM.render(
  <MuiThemeProvider>
    <JssProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App>
            <ConnectedRouter history={history}>{routes}</ConnectedRouter>
          </App>
        </Provider>
      </ThemeProvider>
    </JssProvider>
  </MuiThemeProvider>,
  document.getElementById("root"),
)
