import {
  INPUT,
  EMAIL,
  PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_CONFIRM,
  MOBILE_PREFIX,
  MOBILE_NUMBER,
  MOBILE_OTP,
} from "../../constants/inputs"

export const INPUT_EMAIL = `${INPUT}/${EMAIL}`
export const INPUT_PASSWORD = `${INPUT}/${PASSWORD}`
export const INPUT_NEW_PASSWORD = `${INPUT}/${NEW_PASSWORD}`
export const INPUT_NEW_PASSWORD_CONFIRM = `${INPUT}/${NEW_PASSWORD_CONFIRM}`
export const INPUT_MOBILE_PREFIX = `${INPUT}/${MOBILE_PREFIX}`
export const INPUT_MOBILE_NUMBER = `${INPUT}/${MOBILE_NUMBER}`
export const INPUT_MOBILE_OTP = `${INPUT}/${MOBILE_OTP}`

export const inputEmail = value => ({
  type: INPUT_EMAIL,
  value,
})

export const inputPassword = value => ({
  type: INPUT_PASSWORD,
  value,
})

export const inputNewPassword = value => ({
  type: INPUT_NEW_PASSWORD,
  value,
})

export const inputNewPasswordConfirm = value => ({
  type: INPUT_NEW_PASSWORD_CONFIRM,
  value,
})

export const inputMobilePrefix = value => ({
  type: INPUT_MOBILE_PREFIX,
  value,
})

export const inputMobileNumber = value => ({
  type: INPUT_MOBILE_NUMBER,
  value,
})

export const inputMobileOtp = value => ({
  type: INPUT_MOBILE_OTP,
  value,
})
