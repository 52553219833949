import zipObject from "lodash/zipObject"
import { generateMedia } from "styled-media-query"

import BaseTheme from "./BaseTheme"

// Since the names refer to the breakpoint itself, not the screen width range [1],
// "mobile" width range is represented by MediaQuery.lessThan(TABLET).
// [1] Breakpoints are namely 600px, 1008px and 1280px; while the screen width ranges are:
//
// <600px (lessThan(TABLET))
// 600px <= w < 1008px (between(TABLET, LAPTOP))
// 1008px <= w < 1280px (between(LAPTOP, DESKTOP))
// w >= 1280px (greaterThan(DESKTOP)).

export const TABLET = "tablet"
export const LAPTOP = "laptop"
export const DESKTOP = "desktop"

const MediaQuery = generateMedia(
  zipObject([TABLET, LAPTOP, DESKTOP], BaseTheme.breakpoints.map(bp => bp)),
)

export default MediaQuery
