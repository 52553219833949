import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import Icon from "./Icon"
import { theme, space } from "styled-system"
import isFunction from "lodash/isFunction"

const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background: ${props =>
    theme(`palette.login.${props.themeColor}.backgroundColor`)};
  color: ${props => theme(`palette.login.${props.themeColor}.color`)};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  ${space};
  &:hover {
    background: ${props =>
      theme(`palette.login.${props.themeColor}.backgroundColor`)};
  }
`

const IconButton = props => {
  const { icon, children, href, onClick, ...restProps } = props

  const onButtonClicked = e => {
    if (isFunction(onClick)) {
      onClick(e)
    }
    if (href) {
      // hack for safari, delay page change to show animation,
      // after test the min. delay is 35ms
      setTimeout(() => {
        window.location = href
      }, 35)
    }
  }
  return (
    <StyledButton
      mb={3}
      {...restProps}
      onClick={onButtonClicked}
      p={4}
      fullWidth
    >
      <Icon src={icon} mr={2} />
      {children}
    </StyledButton>
  )
}

IconButton.defaultProps = {
  themeColor: "grey",
}

IconButton.propTypes = {
  themeColor: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]).isRequired,
}

export default IconButton
