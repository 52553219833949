import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import get from "lodash/get"
import pick from "lodash/pick"
import { requestOtpRequest } from "../redux/actions/resetPassword"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { EMAIL } from "../constants/inputs"
import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as APP_STATE_KEY } from "../redux/reducers/app"
import { EMAIL_REQUEST_OTP } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import { Box } from "grid-styled"
import { translate, Interpolate } from "react-i18next"
import Text from "../components/shared/Text"
import Button from "../components/shared/Button"
import { push } from "connected-react-router"
import isEmpty from "lodash/isEmpty"
import config from "../config"
import styled from "styled-components"

const StyledResentButton = styled(Button)`
  display: inline-block;

  button {
    width: auto;
    height: auto;
    min-height: auto;
    padding: 0;
    background-color: transparent;
    line-height: 1.71;
    margin-bottom: 1px;
  }
`

class ForgotPasswordOtpSent extends React.PureComponent {
  componentDidMount() {
    const { dispatch, inputs } = this.props
    if (isEmpty(inputs[EMAIL])) {
      dispatch(push("/forgotPassword"))
    }
  }

  onResendButtonClicked = () => {
    const { dispatch, inputs } = this.props
    dispatch(requestOtpRequest(inputs[EMAIL]))
  }

  render() {
    const { apiRequests, inputs, appInfo, t } = this.props
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("reset_password_email_sent")}
          </Text>
          <Text variant="subheading">{t("reset_password_otp_sent_to")}</Text>
          <Text variant="body2" mb={4}>
            {inputs[EMAIL]}
          </Text>
          <Text variant="subheading">
            {t("click_link_in_email_to_reset_password")}
          </Text>
        </Box>
        <Box>
          <Text textAlign="center" mt={["208px", 0]}>
            <Button
              href={get(appInfo, "homePage", config.hk01BaseUrl)}
              variant="contained"
              color="primary"
              mb={4}
            >
              {t("back_to_index")}
            </Button>
            <Text textAlign="center" variant="subheading" f={1}>
              {t("check_email_reminder")}
              <br />
              <Interpolate
                i18nKey={"email_resent_otp"}
                resent_button={
                  <StyledResentButton
                    color="primary"
                    onClick={this.onResendButtonClicked}
                    isLoading={apiRequests[EMAIL_REQUEST_OTP].isFetching}
                    disableFocusRipple
                    disableRipple
                  >
                    {t("email_resent_otp_button")}
                  </StyledResentButton>
                }
              />
            </Text>
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect(state => ({
    appInfo: get(state[APP_STATE_KEY], "appInfo"),
    apiRequests: pick(state[API_STATE_KEY], [EMAIL_REQUEST_OTP]),
    inputs: pick(state[INPUT_STATE_KEY], [EMAIL]),
  })),
)(ForgotPasswordOtpSent)
