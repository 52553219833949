import union from "lodash/union"
import { APP_INITIALIZED, LOGIN_SUCCESS } from "../actions/controlFlow"
import {
  SET_CALLBACK_URL,
  SET_BIND_PHONE_OPTION,
  GET_APP_INFO_SUCCESS,
  GET_LOGIN_STATUS_SUCCESS,
  SET_APP_ERRORS,
} from "../actions/app"

const STATE_KEY = "app"

const initialState = {
  initialized: false,
  appInfo: null,
  callbackUrl: null,
  isBindPhoneRequired: false,
  loginStatus: null,
  // for errors that make the flow cannot be continued
  errors: [],
}

const appReducer = (state = {}, action) => {
  switch (action.type) {
    case APP_INITIALIZED:
      return {
        ...state,
        initialized: true,
      }

    case SET_CALLBACK_URL:
      return {
        ...state,
        callbackUrl: action.url,
      }

    case SET_BIND_PHONE_OPTION:
      return {
        ...state,
        isBindPhoneRequired: action.option,
      }

    case GET_APP_INFO_SUCCESS:
      return {
        ...state,
        appInfo: action.appInfo,
      }

    case GET_LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        loginStatus: action.status,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginStatus: action.status,
      }

    case SET_APP_ERRORS:
      return {
        ...state,
        errors: union(state.errors, action.errors),
      }

    default:
      return state
  }
}

export default appReducer
export { STATE_KEY, initialState }
