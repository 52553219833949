import { all, take, takeLatest, takeEvery, put } from "redux-saga/effects"

// actions
import * as controlFlowActions from "../actions/controlFlow"
import * as emailActions from "../actions/email"
import * as mobileActions from "../actions/mobile"
import * as appActions from "../actions/app"
import * as bindActions from "../actions/bind"
import * as resetPasswordActions from "../actions/resetPassword"
import * as trackingActions from "../actions/tracking"
// sagas
import * as appSaga from "./app"
import * as emailSaga from "./email"
import * as mobileSaga from "./mobile"
import * as bindSaga from "./bind"
import * as resetPasswordSaga from "./resetPassword"
import * as trackingSaga from "./tracking"

function* rootSaga() {
  // accept all these actions
  yield all([
    takeLatest(appActions.VERIFY_APP_INFO, appSaga.verifyAppInfo),
    takeLatest(appActions.GET_LOGIN_STATUS_REQUEST, appSaga.getLoginStatus),
    takeLatest(appActions.GET_APP_INFO_REQUEST, appSaga.getAppInfo),
    takeLatest(emailActions.AUTHENTICATE_REQUEST, emailSaga.authenticate),
    takeLatest(emailActions.REQUEST_OTP_REQUEST, emailSaga.requestOtp),
    takeLatest(emailActions.VERIFY_OTP_REQUEST, emailSaga.verifyOtp),
    takeLatest(mobileActions.REQUEST_OTP_REQUEST, mobileSaga.requestOtp),
    takeLatest(mobileActions.VERIFY_OTP_REQUEST, mobileSaga.verifyOtp),
    takeLatest(bindActions.REQUEST_OTP_REQUEST, bindSaga.requestOtp),
    takeLatest(bindActions.VERIFY_OTP_REQUEST, bindSaga.verifyOtp),
    takeLatest(bindActions.MERGE_REQUEST, bindSaga.mergeRequest),
    takeLatest(
      resetPasswordActions.REQUEST_OTP_REQUEST,
      resetPasswordSaga.requestOtp,
    ),
    takeLatest(
      resetPasswordActions.RESET_PASSWORD_REQUEST,
      resetPasswordSaga.resetPassword,
    ),
    takeLatest(controlFlowActions.LOGIN_SUCCESS, appSaga.loginSuccess),
    takeLatest(
      controlFlowActions.REDIRECT_TO_CALLBACK_URL,
      appSaga.redirectBack,
    ),
    takeLatest(
      controlFlowActions.HANDLE_LOGGED_IN_REDIRECT,
      appSaga.handleLoggedInRedirect,
    ),
    takeLatest(
      controlFlowActions.REDIRECT_TO_POST_ACC_MERGE_CB_URL,
      appSaga.redirectToPostAccMergeCbUrl,
    ),
    takeLatest(
      trackingActions.SEND_ATTRIBUTION_REQUEST,
      trackingSaga.sendAttribution,
    ),
    takeLatest(
      controlFlowActions.RUN_VERIFY_EMAIL_OTP_FLOW,
      appSaga.verifyEmailOtpFlow,
    ),
    takeEvery(trackingActions.TRACK_EVENT, trackingSaga.trackEvent),
  ])

  // wait for `SET_LOGIN_STATUS` and `SET_APP_INFO`,
  // then set the app as initialized
  yield all([
    take(appActions.GET_LOGIN_STATUS_SUCCESS),
    take(controlFlowActions.VERIFY_APP_INFO_SUCCESS),
  ])
  yield put(controlFlowActions.appInitialized())
}

export default rootSaga
