import i18n from "i18next"
import { reactI18nextModule } from "react-i18next"
import zhHkTranslations from "./locales/zh-HK"

i18n.use(reactI18nextModule).init({
  lng: "zh-HK",
  resources: {
    "zh-HK": {
      translation: zhHkTranslations,
    },
  },
  debug: false,
})

export default i18n
