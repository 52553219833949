import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "./Icon"
import { Link } from "react-router-dom"

const StyledDiv = styled("div")`
  padding-left: 20px;
  padding-right: 20px;
`
const StyledIcon = styled(Icon)`
  box-shadow: 0px 2px 2px #ccc;
  border-radius: 48px;
`
const CircleButton = props => {
  const { icon, href, to, ...restProps } = props
  const IconContainer = <StyledIcon src={icon} width={"48px"} height={"48px"} />

  return (
    <StyledDiv {...restProps}>
      {href ? (
        <a href={href}>{IconContainer}</a>
      ) : (
        <Link to={to}>{IconContainer}</Link>
      )}
    </StyledDiv>
  )
}

CircleButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]).isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
}

export default CircleButton
