import React from "react"
import {
  MuiThemeProvider as Provider,
  createMuiTheme,
} from "@material-ui/core/styles"
import BaseTheme from "./BaseTheme"

// This theme provider is only used to override the default colors/styles of Material UI,
// DO NOT define new color here,
// only do it in `BaseTheme.js`

const theme = createMuiTheme({
  palette: {
    primary: {
      light: BaseTheme.colors.primaryLighten,
      main: BaseTheme.colors.primary,
      dark: BaseTheme.colors.primaryDarken,
    },
    action: {
      disabled: null, // use the same color in default state
      disabledBackground: null, // use the same background in default state
    },
  },
  typography: {
    fontFamily: BaseTheme.font,
    fontSize: `${BaseTheme.rootFontSize}px`,
    button: {
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.71,
      textTransform: "none",
      textAlign: "center",
      boxShadow: "0 2px 4px 0 rgba(26, 30, 40, 0.1)",
    },
  },
})

const MuiThemeProvider = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
)

export default MuiThemeProvider
