import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { translate, Interpolate } from "react-i18next"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import pick from "lodash/pick"
import concat from "lodash/concat"
import { Box } from "grid-styled"

import { STATE_KEY as API_STATE_KEY } from "../redux/reducers/apiRequests"
import { STATE_KEY as INPUT_STATE_KEY } from "../redux/reducers/inputs"
import { RESET_PASSWORD_RESET_PASSWORD } from "../constants/apiRequests"
import PageContainer from "../components/shared/PageContainer"
import Text from "../components/shared/Text"
import TextField from "../components/shared/TextField"
import Button from "../components/shared/Button"
import { NEW_PASSWORD, NEW_PASSWORD_CONFIRM } from "../constants/inputs"
import { resetPasswordRequest } from "../redux/actions/resetPassword"
import {
  inputNewPassword,
  inputNewPasswordConfirm,
} from "../redux/actions/inputs"
import {
  NEW_PASSWORD_CONFIRM_MISMATCH,
  NEW_PASSWORD_FORMAT_INVALID,
} from "../constants/errors"

class ResetPassword extends React.PureComponent {
  state = {
    token: "",
    passwordErrors: [],
    passwordConfirmErrors: [],
  }
  componentDidMount() {
    const { match } = this.props
    const token = get(match, "params.token")
    this.setState({
      token,
    })
  }
  isValidPassword = (password) => {
    const errors = []
    const matcher = /^[\w-!@#$%^&*()+|~=`{}[\]:";'<>?,./]{8,}$/
    if (!matcher.test(password)) {
      errors.push(NEW_PASSWORD_FORMAT_INVALID)
    }
    this.setState({
      passwordErrors: errors,
    })
    return isEmpty(errors)
  }
  isPasswordMatch = (password, passwordConfirm) => {
    const errors = []
    if (password !== passwordConfirm) {
      errors.push(NEW_PASSWORD_CONFIRM_MISMATCH)
    }

    this.setState({
      passwordConfirmErrors: errors,
    })
    return isEmpty(errors)
  }
  onInputChanged = (e) => {
    const { dispatch } = this.props
    const { name, value } = e.target
    if (name === NEW_PASSWORD) {
      dispatch(inputNewPassword(value))
    } else {
      dispatch(inputNewPasswordConfirm(value))
    }
  }
  onResetButtonClicked = () => {
    const { dispatch, inputs } = this.props
    const { token } = this.state

    if (
      this.isValidPassword(inputs[NEW_PASSWORD]) &&
      this.isPasswordMatch(inputs[NEW_PASSWORD], inputs[NEW_PASSWORD_CONFIRM])
    ) {
      dispatch(resetPasswordRequest(token, inputs[NEW_PASSWORD]))
    }
  }
  render() {
    const { apiRequests, inputs, t } = this.props
    const { passwordErrors, passwordConfirmErrors } = this.state
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t("reset_password")}
          </Text>
          <Text variant="subheading" mb={[6, 7]}>
            <Interpolate i18nKey={"reset_password_desc"} />
          </Text>
          <TextField
            mb={[6, 7]}
            type="password"
            name={NEW_PASSWORD}
            value={inputs[NEW_PASSWORD]}
            placeholder={t("new_password")}
            onChange={this.onInputChanged}
            disabled={apiRequests[RESET_PASSWORD_RESET_PASSWORD].isFetching}
            errors={concat(
              apiRequests[RESET_PASSWORD_RESET_PASSWORD].errors,
              passwordErrors,
            )}
            fullWidth
            withClear
            autoFocus
            data-test-id={"ssoPwResetPassword-newPassword-inputPassword"}
            data-testid={"ssoPwResetPassword-newPassword-inputPassword"}
          />
          <TextField
            mb={6}
            type="password"
            name={NEW_PASSWORD_CONFIRM}
            value={inputs[NEW_PASSWORD_CONFIRM]}
            placeholder={t("new_password_confirm")}
            onChange={this.onInputChanged}
            disabled={apiRequests[RESET_PASSWORD_RESET_PASSWORD].isFetching}
            errors={passwordConfirmErrors}
            fullWidth
            withClear
            data-test-id={"ssoPwResetPassword-newPassword2-inputPassword"}
            data-testid={"ssoPwResetPassword-newPassword2-inputPassword"}
          />
        </Box>
        <Box>
          <Text textAlign="center" mt={2}>
            <Button
              variant="contained"
              color="primary"
              isLoading={apiRequests[RESET_PASSWORD_RESET_PASSWORD].isFetching}
              onClick={this.onResetButtonClicked}
              disabled={
                isEmpty(inputs[NEW_PASSWORD]) ||
                isEmpty(inputs[NEW_PASSWORD_CONFIRM])
              }
              data-test-id={"ssoPwResetPassword-reset-button"}
              data-testid={"ssoPwResetPassword-reset-button"}
            >
              {t("reset")}
            </Button>
          </Text>
        </Box>
      </PageContainer>
    )
  }
}

export default compose(
  translate(),
  connect((state) => ({
    apiRequests: pick(state[API_STATE_KEY], [RESET_PASSWORD_RESET_PASSWORD]),
    inputs: pick(state[INPUT_STATE_KEY], [NEW_PASSWORD, NEW_PASSWORD_CONFIRM]),
  })),
)(ResetPassword)
