import {
  EMAIL,
  PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_CONFIRM,
  MOBILE_PREFIX,
  MOBILE_NUMBER,
  MOBILE_OTP,
} from "../../constants/inputs"
import {
  INPUT_EMAIL,
  INPUT_PASSWORD,
  INPUT_NEW_PASSWORD,
  INPUT_NEW_PASSWORD_CONFIRM,
  INPUT_MOBILE_PREFIX,
  INPUT_MOBILE_NUMBER,
  INPUT_MOBILE_OTP,
} from "../actions/inputs"

const STATE_KEY = "inputs"

const initialState = {
  [EMAIL]: "",
  [PASSWORD]: "",
  [MOBILE_PREFIX]: "852",
  [MOBILE_NUMBER]: "",
  [MOBILE_OTP]: "",
  [NEW_PASSWORD]: "",
  [NEW_PASSWORD_CONFIRM]: "",
}

const inputReducer = (state = {}, action) => {
  switch (action.type) {
    case INPUT_EMAIL:
      return {
        ...state,
        [EMAIL]: action.value,
      }

    case INPUT_PASSWORD:
      return {
        ...state,
        [PASSWORD]: action.value,
      }

    case INPUT_NEW_PASSWORD:
      return {
        ...state,
        [NEW_PASSWORD]: action.value,
      }

    case INPUT_NEW_PASSWORD_CONFIRM:
      return {
        ...state,
        [NEW_PASSWORD_CONFIRM]: action.value,
      }

    case INPUT_MOBILE_PREFIX:
      return {
        ...state,
        [MOBILE_PREFIX]: action.value,
      }

    case INPUT_MOBILE_NUMBER:
      return {
        ...state,
        [MOBILE_NUMBER]: action.value,
      }

    case INPUT_MOBILE_OTP:
      return {
        ...state,
        [MOBILE_OTP]: action.value,
      }

    default:
      return state
  }
}

export default inputReducer
export { STATE_KEY, initialState }
