import {
  MOBILE_REQUEST_OTP,
  MOBILE_VERIFY_OTP,
} from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const REQUEST_OTP_REQUEST = `${MOBILE_REQUEST_OTP}/${REQUEST}`
export const REQUEST_OTP_SUCCESS = `${MOBILE_REQUEST_OTP}/${SUCCESS}`
export const REQUEST_OTP_ERROR = `${MOBILE_REQUEST_OTP}/${ERROR}`

export const VERIFY_OTP_REQUEST = `${MOBILE_VERIFY_OTP}/${REQUEST}`
export const VERIFY_OTP_SUCCESS = `${MOBILE_VERIFY_OTP}/${SUCCESS}`
export const VERIFY_OTP_ERROR = `${MOBILE_VERIFY_OTP}/${ERROR}`

export const requestOtpRequest = (phone, reCaptchaToken) => ({
  type: REQUEST_OTP_REQUEST,
  phone,
  reCaptchaToken,
})

export const requestOtpSuccess = () => ({
  type: REQUEST_OTP_SUCCESS,
})

export const requestOtpError = (errors) => ({
  type: REQUEST_OTP_ERROR,
  errors,
})

export const verifyOtpRequest = (phone, token) => ({
  type: VERIFY_OTP_REQUEST,
  phone,
  token,
})

export const verifyOtpSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
})

export const verifyOtpError = (errors) => ({
  type: VERIFY_OTP_ERROR,
  errors,
})
