import { combineReducers } from "redux"
import appReducer, {
  STATE_KEY as APP_STATE_KEY,
  initialState as appInitialState,
} from "./app"
import apiRequestReducer, {
  STATE_KEY as API_STATE_KEY,
  initialState as apiRequestInitialState,
} from "./apiRequests"
import inputReducer, {
  STATE_KEY as INPUT_STATE_KEY,
  initialState as inputInitialState,
} from "./inputs"
import dialogReducer, {
  STATE_KEY as DIALOG_STATE_KEY,
  initialState as dialogInitialState,
} from "./dialogs"

const initialState = {
  [APP_STATE_KEY]: appInitialState,
  [API_STATE_KEY]: apiRequestInitialState,
  [INPUT_STATE_KEY]: inputInitialState,
  [DIALOG_STATE_KEY]: dialogInitialState,
}

export default combineReducers({
  [APP_STATE_KEY]: appReducer,
  [API_STATE_KEY]: apiRequestReducer,
  [INPUT_STATE_KEY]: inputReducer,
  [DIALOG_STATE_KEY]: dialogReducer,
})
export { initialState }
