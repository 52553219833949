import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { theme } from "styled-system"

const TextLink = props => {
  const {
    children,
    component: Component,
    href,
    className,
    ...restProps
  } = props

  if (href) {
    return (
      <span className={className}>
        <a href={href} {...restProps}>
          {children}
        </a>
      </span>
    )
  }

  return (
    <span className={className}>
      <Component {...restProps}>{children}</Component>
    </span>
  )
}

const StyledLink = styled(TextLink)`
  a {
    text-decoration: none;
    color: ${theme("palette.common.link")};
  }
`

TextLink.propTypes = {
  component: PropTypes.func,
  href: PropTypes.string,
}

export default StyledLink
