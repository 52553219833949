import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import get from "lodash/get"
import has from "lodash/has"
import toNumber from "lodash/toNumber"
import qs from "qs"
import MediaQuery, { TABLET } from "./theme/MediaQuery"
import { Flex } from "grid-styled"
import "./services/i18n"
import {
  setBindPhoneOption,
  getLoginStatusRequest,
  verifyAppInfo,
} from "./redux/actions/app"
import {
  runVerifyEmailOtpFlow,
  handleLoggedInRedirect,
} from "./redux/actions/controlFlow"
import { sendAttributionRequest } from "./redux/actions/tracking"
import { STATE_KEY as APP_STATE_KEY } from "./redux/reducers/app"
import Spinner from "./components/shared/Spinner"
import ErrorPage from "./components/Error/ErrorPage"
import config from "./config"

// Known issue: content sticks to the top of screen on IE11
const AppContainer = styled(Flex)`
  min-height: 100vh;
  ${MediaQuery.lessThan(TABLET)`
    min-height: 0;
  `};
`

const SpinnerContainer = styled(Flex)`
  ${MediaQuery.lessThan(TABLET)`
    min-height: 100vh;
  `};
`

class App extends React.PureComponent {
  isResetPassword = (source) => {
    const { location } = this.props
    return location.pathname.includes("/resetPassword/") && source !== "sso-web"
  }

  isVerifyPage = () => {
    const { location } = this.props
    return location.pathname.includes("/verify/")
  }

  isLoginPage = () => {
    const { location } = this.props
    return location.pathname === "/"
  }

  getConfig = (location) => {
    const parsed = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    return has(parsed, "emailState") ? JSON.parse(parsed.emailState) : parsed
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    // take care of normal flow vs reset password flow
    const { appId, callbackUrl, bindPhone, attr, source } = this.getConfig(
      location,
    )

    if (this.isResetPassword(source)) {
      const token = location.pathname.replace("/member/resetPassword/", "") // extract the token
      window.location.replace(
        `${config.hk01BaseUrl}/member/reset_password/${token}${location.search}`,
      )
      return
    } else if (this.isVerifyPage(source)) {
      const token = location.pathname.replace(`/member/verify/`, "") // extract the token
      if (source !== "sso-web") {
        // for backward capability
        window.location.replace(
          `${config.hk01BaseUrl}/member/verification/${token}${location.search}`,
        )
        return
      } else {
        dispatch(runVerifyEmailOtpFlow(appId, token))
      }
    } else {
      dispatch(setBindPhoneOption(toNumber(bindPhone) === 1))
      dispatch(verifyAppInfo(appId, callbackUrl))
      dispatch(
        getLoginStatusRequest({
          callbackUrl,
          appId,
        }),
      )
    }

    if (attr) {
      // send member attribution
      try {
        const decodedAttr = JSON.parse(attr)
        dispatch(sendAttributionRequest(decodedAttr))
      } catch (error) {
        // in case decode failed, do nothing
      }
    }
  }

  componentDidUpdate(preProps) {
    const { dispatch, callbackUrl, appInfo, loginStatus } = this.props
    if (
      this.isLoginPage() &&
      appInfo &&
      // verified callback url is exist
      callbackUrl &&
      loginStatus === "connected"
    ) {
      dispatch(handleLoggedInRedirect({ appId: appInfo.appId, callbackUrl }))
    }
  }

  renderContent = () => {
    const { errors, initialized, loginStatus } = this.props

    if (errors.length) {
      return <ErrorPage errors={errors} />
    }

    if (initialized) {
      if (this.isLoginPage()) {
        if (loginStatus && loginStatus !== "connected") {
          return this.props.children
        }
      } else {
        return this.props.children
      }
    }

    return (
      <SpinnerContainer alignItems="center">
        <Spinner />
      </SpinnerContainer>
    )
  }
  render() {
    return (
      <AppContainer
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {this.renderContent()}
      </AppContainer>
    )
  }
}

export default connect((state) => ({
  errors: get(state, [APP_STATE_KEY, "errors"]),
  initialized: get(state, [APP_STATE_KEY, "initialized"]),
  location: get(state, ["router", "location"]),
  appInfo: get(state, [APP_STATE_KEY, "appInfo"]),
  callbackUrl: get(state, [APP_STATE_KEY, "callbackUrl"]),
  loginStatus: get(state, [APP_STATE_KEY, "loginStatus", "status"]),
}))(App)
