import React from "react"
import PropTypes from "prop-types"
import { translate } from "react-i18next"
import { Box } from "grid-styled"

import PageContainer from "../shared/PageContainer"
import Text from "../shared/Text"
import Button from "../shared/Button"
import { UFO } from "../../static/icons"
import config from "../../config"

class ErrorPage extends React.PureComponent {
  render() {
    const { t, errors } = this.props
    return (
      <PageContainer spreadContent>
        <Box>
          <Text variant="title" mb={2}>
            {t(`error_title`)}
          </Text>
          <Text variant="subheading" mb={1}>
            {t("error_desc")}
          </Text>
          <Text variant="display4" f={0}>
            {`${t("error_code")} : ${errors}`}
          </Text>
        </Box>
        <Text textAlign="center" my={["80px", 0]}>
          <img src={UFO} alt="not_found" />
        </Text>
        <Text textAlign="center">
          <Button variant="contained" color="primary" href={config.hk01BaseUrl}>
            {t("back_to_hk01")}
          </Button>
        </Text>
      </PageContainer>
    )
  }
}

ErrorPage.propTypes = {
  errors: PropTypes.array.isRequired,
}

export default translate()(ErrorPage)
