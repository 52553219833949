import { TRACKING_SEND_ATTRIBUTION } from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const SEND_ATTRIBUTION_REQUEST = `${TRACKING_SEND_ATTRIBUTION}/${REQUEST}`
export const SEND_ATTRIBUTION_SUCCESS = `${TRACKING_SEND_ATTRIBUTION}/${SUCCESS}`
export const SEND_ATTRIBUTION_ERROR = `${TRACKING_SEND_ATTRIBUTION}/${ERROR}`

export const TRACK_EVENT = "TRACK_EVENT"

export const sendAttributionRequest = attr => ({
  type: SEND_ATTRIBUTION_REQUEST,
  attr,
})

export const sendAttributionSuccess = () => ({
  type: SEND_ATTRIBUTION_SUCCESS,
})

export const sendAttributionError = errors => ({
  type: SEND_ATTRIBUTION_ERROR,
  errors,
})

export const trackEvent = event => ({
  type: TRACK_EVENT,
  event,
})
