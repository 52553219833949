import {
  RESET_PASSWORD_REQUEST_OTP,
  RESET_PASSWORD_RESET_PASSWORD,
} from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const REQUEST_OTP_REQUEST = `${RESET_PASSWORD_REQUEST_OTP}/${REQUEST}`
export const REQUEST_OTP_SUCCESS = `${RESET_PASSWORD_REQUEST_OTP}/${SUCCESS}`
export const REQUEST_OTP_ERROR = `${RESET_PASSWORD_REQUEST_OTP}/${ERROR}`

export const RESET_PASSWORD_REQUEST = `${RESET_PASSWORD_RESET_PASSWORD}/${REQUEST}`
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD_RESET_PASSWORD}/${SUCCESS}`
export const RESET_PASSWORD_ERROR = `${RESET_PASSWORD_RESET_PASSWORD}/${ERROR}`

export const requestOtpRequest = email => ({
  type: REQUEST_OTP_REQUEST,
  email,
})

export const requestOtpSuccess = () => ({
  type: REQUEST_OTP_SUCCESS,
})

export const requestOtpError = errors => ({
  type: REQUEST_OTP_ERROR,
  errors,
})

export const resetPasswordRequest = (token, newPassword) => ({
  type: RESET_PASSWORD_REQUEST,
  token,
  newPassword,
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordError = errors => ({
  type: RESET_PASSWORD_ERROR,
  errors,
})
