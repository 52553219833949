import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import MaterialTextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import isEmpty from "lodash/isEmpty"
import cleanElement from "clean-element"
import { ifProp } from "styled-tools"
import Icon from "./Icon"
import { CloseSvg } from "../../static/icons"
import TextLink from "./TextLink"
import { theme, space } from "styled-system"
import { translate } from "react-i18next"

const CleanTextField = cleanElement(MaterialTextField)
CleanTextField.propTypes = {
  i18n: PropTypes.object,
  tReady: PropTypes.bool,
}

const StyledTextField = styled(CleanTextField)`
  ${space};

  input {
    min-height: 24px;
    padding: 0;
    margin: 8px 0;
    line-height: 1.5;
    color: ${theme("palette.common.text")};
    caret-color: #0033ee;
    align-self: flex-end;
  }

  input::placeholder,
  input:disabled {
    color: rgba(26, 30, 40, 0.48);
  }

  div:hover::before {
    border-bottom-width: 1px !important;
  }

  div::after {
    border-bottom-width: 1px;
    border-bottom-color: ${ifProp("error", "#ff495c", "#1745ef")};
  }

  p {
    font-size: 14px;
    line-height: 1.14;
    color: #ff495c;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 0;
`

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const StyledTextLink = styled(TextLink)`
  display: inline-flex;

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 24px;
    opacity: 0.2;
    background-color: #1a1e28;
    margin: 0 8px;
  }

  a {
    color: ${theme("colors.darkGrey")};
    line-height: 1.71;
    font-size: 14px;
  }
`

class TextField extends React.PureComponent {
  state = {
    showPassword: false,
  }

  setInputRef = (input) => {
    this.textInput = input
  }

  handleClear = (e) => {
    const setValue = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value",
    ).set
    const event = new Event("input", { bubbles: true })

    setValue.call(this.textInput, "")
    this.textInput.dispatchEvent(event)
    this.textInput.focus()
  }

  handleShowPassword = (e) => {
    e.preventDefault()
    this.setState({ showPassword: !this.state.showPassword })
    this.textInput.focus()
  }

  getInputFieldType = () => {
    if (this.props.type === "password" && this.state.showPassword === true) {
      return "text"
    }

    return this.props.type
  }

  render() {
    const {
      errors,
      withClear,
      onChange,
      component: inputComponent,
      type,
      t,
      inputProps,
      "data-test-id": dataTestId,
      "data-testid": testId,
      ...restProps
    } = this.props

    const testIdPrefix = testId && testId.replace(/-([^-]+?)$/, "")

    return (
      <StyledTextField
        type={this.getInputFieldType()}
        error={!isEmpty(errors)}
        helperText={
          !isEmpty(errors) &&
          errors.map((error, i) => (
            <span
              key={i}
              data-test-id={testIdPrefix && `${testIdPrefix}-textError`}
              data-testid={testIdPrefix && `${testIdPrefix}-textError`}
            >
              {t(`errors.${error}`, {
                defaultValue: t("errors.UNKNOWN_API_ERROR"),
              })}
              <br />
            </span>
          ))
        }
        onChange={onChange}
        InputProps={{
          inputRef: this.setInputRef,
          inputComponent,
          inputProps: {
            ...inputProps,
            "data-test-id": dataTestId || false,
            "data-testid": testId,
          },
          endAdornment: (
            <StyledInputAdornment position="end">
              {withClear && !this.props.disabled && (
                <StyledIcon
                  src={CloseSvg}
                  width={"16px"}
                  height={"16px"}
                  onClick={this.handleClear}
                />
              )}
              {type === "password" && (
                <StyledTextLink
                  href={"###"}
                  onClick={this.handleShowPassword}
                  data-test-id={testIdPrefix && `${testIdPrefix}-buttonShow`}
                  data-testid={testIdPrefix && `${testIdPrefix}-buttonShow`}
                >
                  {this.state.showPassword ? t("hide") : t("show")}
                </StyledTextLink>
              )}
            </StyledInputAdornment>
          ),
        }}
        {...restProps}
      />
    )
  }
}

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  errors: PropTypes.array,
  autoFocus: PropTypes.bool,
  withClear: PropTypes.bool,
  onChange: PropTypes.func,
  component: PropTypes.func,
}

TextField.defaultProps = {
  type: "text",
  errors: [],
  autoFocus: false,
  withClear: false,
}

export default translate()(TextField)
