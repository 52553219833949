import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { ifProp } from "styled-tools"
import { Modal, Paper } from "@material-ui/core"
import MediaQuery, { TABLET } from "../../theme/MediaQuery"

const PaperContainer = styled(({ small, ...props }) => <Paper {...props} />)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 394px;
  ${ifProp(
    "small",
    css`
      height: 464px;
    `,
    css`
      min-height: 464px;
    `,
  )};
  display: flex;
  flex-direction: column;

  ${MediaQuery.lessThan(TABLET)`
    width: 265px;
    ${ifProp(
      "small",
      css`
        height: 280px;
      `,
      css`
        min-height: 280px;
      `,
    )};
  `};
`

const Dialog = ({ isOpened, onClose, children, small }) => (
  <Modal open={isOpened} onClose={onClose}>
    <PaperContainer small={small}>{children}</PaperContainer>
  </Modal>
)

Dialog.defaultProps = {
  isOpened: false,
  small: false,
  onClose: () => {},
}

Dialog.propTypes = {
  isOpened: PropTypes.bool,
  small: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Dialog
