import {
  BIND_REQUEST_OTP,
  BIND_VERIFY_OTP,
  BIND_MERGE_OTP_REQUEST,
} from "../../constants/apiRequests"
import { REQUEST, SUCCESS, ERROR } from "../../constants/apiActions"

export const REQUEST_OTP_REQUEST = `${BIND_REQUEST_OTP}/${REQUEST}`
export const REQUEST_OTP_SUCCESS = `${BIND_REQUEST_OTP}/${SUCCESS}`
export const REQUEST_OTP_ERROR = `${BIND_REQUEST_OTP}/${ERROR}`

export const MERGE_REQUEST = `${BIND_MERGE_OTP_REQUEST}/${REQUEST}`
export const MERGE_SUCCESS = `${BIND_MERGE_OTP_REQUEST}/${SUCCESS}`
export const MERGE_ERROR = `${BIND_MERGE_OTP_REQUEST}/${ERROR}`

export const VERIFY_OTP_REQUEST = `${BIND_VERIFY_OTP}/${REQUEST}`
export const VERIFY_OTP_SUCCESS = `${BIND_VERIFY_OTP}/${SUCCESS}`
export const VERIFY_OTP_ERROR = `${BIND_VERIFY_OTP}/${ERROR}`

export const requestOtpRequest = (phone, reCaptchaToken) => ({
  type: REQUEST_OTP_REQUEST,
  phone,
  reCaptchaToken,
})

export const requestOtpSuccess = () => ({
  type: REQUEST_OTP_SUCCESS,
})

export const requestOtpError = (errors) => ({
  type: REQUEST_OTP_ERROR,
  errors,
})

export const mergeOtpRequest = () => ({
  type: MERGE_REQUEST,
})

export const mergeOtpSuccess = () => ({
  type: MERGE_SUCCESS,
})

export const mergeOtpError = (errors) => ({
  type: MERGE_ERROR,
  errors,
})

export const verifyOtpRequest = (phone, token, action) => ({
  type: VERIFY_OTP_REQUEST,
  phone,
  token,
  action,
})

export const verifyOtpSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
})

export const verifyOtpError = (errors) => ({
  type: VERIFY_OTP_ERROR,
  errors,
})
